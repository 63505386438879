/* eslint-disable import/prefer-default-export */

import { UpdateVisitorGroup, UpdateVisitorGroup_updateVisitorGroup } from 'graphql/generated/UpdateVisitorGroup';
import { createMockedMobileDevice } from 'mocks/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitor } from 'mocks/objectsBaseOnSchemaType/visitor';
import { createMockedVisitorGroupWithMembersAndType } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithMembersAndTypeCoContractors';

export function mockedUpdateVisitorGroupData(
  updateVisitorGroup: UpdateVisitorGroup_updateVisitorGroup
): UpdateVisitorGroup {
  return {
    updateVisitorGroup
  };
}

export const mockedUpdateVisitorGroupDataForMutation = mockedUpdateVisitorGroupData(
  createMockedVisitorGroupWithMembersAndType({
    id: 'mockedVisitorGroupIdCoContractors1',
    members: [
      createMockedVisitor({
        id: 'mockedVisitorIdCoContractor1',
        name: 'mockedVisitorNameCoContractor1',
        email: 'mockedVisitorEmailCoContractor1'
      }),
      createMockedVisitor({
        id: 'mockedVisitorIdCoContractor2',
        name: 'mockedVisitorNameCoContractor2',
        email: 'mockedVisitorEmailCoContractor2',
        mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
      })
    ],
    name: 'co-contractors1-new'
  })
);
