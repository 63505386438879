/* eslint-disable import/prefer-default-export */

import { SetVisitorById, SetVisitorById_setVisitorById } from 'graphql/generated/SetVisitorById';
import { createMockedVisitor } from 'mocks/objectsBaseOnSchemaType/visitor';

export function mockedSetVisitorByIdData(setVisitorById: SetVisitorById_setVisitorById): SetVisitorById {
  return {
    setVisitorById
  };
}

export const mockedSetVisitorDataForMutation = mockedSetVisitorByIdData(
  createMockedVisitor({ id: 'mockedCoContractorId' })
);

export const mockedSetVisitorDataForMutation1 = mockedSetVisitorByIdData(
  createMockedVisitor({
    id: 'mockedVisitorIdCoContractor6',
    name: 'mockedVisitorEmailCoContractor6Edited',
    email: 'mockedVisitorEmailCoContractor6'
  })
);

export const mockedUpdateInstallerDataForMutation = mockedSetVisitorByIdData(
  createMockedVisitor({
    id: 'mockedVisitorIdInstaller1',
    name: 'mockedVisitorNameInstaller1Updated',
    email: 'mockedVisitorEmailInstaller1'
  })
);
