/* eslint-disable import/prefer-default-export */

import { AddVisitorGroup, AddVisitorGroup_addVisitorGroup } from 'graphql/generated/AddVisitorGroup';
import { createMockedVisitorGroupWithMembersAndType } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithMembersAndTypeCoContractors';

export function mockedAddVisitorGroupData(addVisitorGroup: AddVisitorGroup_addVisitorGroup): AddVisitorGroup {
  return {
    addVisitorGroup
  };
}

export const mockedAddVisitorGroupDataForMutation = mockedAddVisitorGroupData(
  createMockedVisitorGroupWithMembersAndType({
    id: 'mockedVisitorGroupIdCoContractorsNew',
    members: [],
    name: 'co-contractors-new'
  })
);
