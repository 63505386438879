/* eslint-disable import/prefer-default-export */

import { AddVisitor, AddVisitor_addVisitor } from 'graphql/generated/AddVisitor';
import { createMockedVisitor } from 'mocks/objectsBaseOnSchemaType/visitor';

export function mockedAddVisitorData(addVisitor: AddVisitor_addVisitor): AddVisitor {
  return {
    addVisitor
  };
}

export const mockedAddVisitorDataForMutation = mockedAddVisitorData(
  createMockedVisitor({
    id: 'mockedVisitorIdCoContractorNew',
    name: 'mockedVisitorNameCoContractorNew',
    email: 'mockedVisitorEmailCoContractorNew'
  })
);
