/* eslint-disable import/prefer-default-export */

import { UpdateDoorWithLocations, UpdateDoorWithLocations_updateDoor } from 'graphql/generated/UpdateDoorWithLocations';
import { createMockedDoorWithLocations } from 'mocks/objectsBaseOnSchemaType/doorWithLocations';

export function mockedUpdateDoorWithLocationsData(
  updateDoor: UpdateDoorWithLocations_updateDoor
): UpdateDoorWithLocations {
  return {
    updateDoor
  };
}

export const mockedUpdateDoorWithLocationsDataForMutation = mockedUpdateDoorWithLocationsData(
  createMockedDoorWithLocations({
    id: 'mockedDoorId0',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'mockedDoorNameUpdated'
  })
);
