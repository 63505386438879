/* eslint-disable import/prefer-default-export */

import { UpdateAccessGrant, UpdateAccessGrant_updateAccessGrant } from 'graphql/generated/UpdateAccessGrant';
import { createMockedAccessGrantWeekSchedule } from 'mocks/objectsBaseOnSchemaType/accessGrantWeekSchedule';
import { createMockedAccessGrantWeekScheduleDay } from 'mocks/objectsBaseOnSchemaType/accessGrantWeekScheduleDay';
import { createMockedAccessGrantWithSchedules } from 'mocks/objectsBaseOnSchemaType/accessGrantWithSchedules';

export function mockedUpdateAccessGrant(updateAccessGrant: UpdateAccessGrant_updateAccessGrant): UpdateAccessGrant {
  return {
    updateAccessGrant
  };
}

export const mockedUpdateAccessGrantForMutation1 = mockedUpdateAccessGrant(
  createMockedAccessGrantWithSchedules({
    id: 'mockedAccessGrantWithDays',
    accessSchedule: createMockedAccessGrantWeekSchedule({
      weekDays: [createMockedAccessGrantWeekScheduleDay({ dayName: 'monday', from: '08:00', to: '21:00' })]
    })
  })
);

export const mockedUpdateAccessGrantForMutation2 = mockedUpdateAccessGrant(
  createMockedAccessGrantWithSchedules({
    id: 'mockedAccessGrantWithDays5',
    accessSchedule: createMockedAccessGrantWeekSchedule({
      weekDays: [
        createMockedAccessGrantWeekScheduleDay({ dayName: 'sunday', to: '08:00', from: '21:00' }),
        createMockedAccessGrantWeekScheduleDay({ dayName: 'saturday', to: '08:00', from: '21:00' })
      ]
    })
  })
);
