import React from 'react';
import { css } from 'styled-components';

type FieldType = 'textField' | 'combo';

interface TextFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}
interface ComboBoxProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {}

type FieldProps<T extends FieldType> = T extends 'textField' ? TextFieldProps : ComboBoxProps;

export interface CssProps {
  failed?: boolean;
  disabled?: boolean;
  customRequired?: boolean;
  hideMarginTop?: boolean;
}

export type Props<T extends FieldType> = CssProps & FieldProps<T>;

export const FieldCss = css<CssProps>`
  padding: 0.375rem 0.625rem;
  height: 2.5rem;
  background: 0 ${({ theme }) => theme.colors.b5};
  border: 1px solid ${(props) => (props.failed ? props.theme.colors.error : props.theme.colors.b6)};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.125rem;
  line-height: 1.6875rem;
  ${({ hideMarginTop }) => (hideMarginTop ? '' : 'margin-top: 0.375rem')};
  color: ${(props) => props.theme.colors.dTextHigh};

  ${({ disabled, theme }) =>
    disabled
      ? `
      background-color: ${theme.colors.b5};
      border: 1px solid ${theme.colors.b6};
      opacity: 0.5;

      &::placeholder {
        color: unset;
      }`
      : ''};

  ::-webkit-calendar-picker-indicator {
    background: none;
  }
`;
