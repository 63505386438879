import { GetLocationsWithDoorsForTenant_getLocationsForTenant_doors } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import React from 'react';
import { LocationsWithDoorsState } from 'state/types';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Label from 'ui/atoms/Label/Label';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import LockGroup from 'ui/molecules/LockGroup/LockGroup';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleOpenCreateLockGroupModal: () => void;
  handleOpenEditLockGroupModal: (
    locationId: string,
    lockGroupName: string,
    selectedDoors: GetLocationsWithDoorsForTenant_getLocationsForTenant_doors[]
  ) => void;
  searchGroupInputValue: string;
  searchGroupInputOnChange: (value: string) => void;
  handleOnDeleteLockGroupIconClick: (locationId: string) => void;
  doorGroups: LocationsWithDoorsState;
}

const LockGroupWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin: 1rem 0;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 40vh;

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.b5};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.b6};
  }
`;

const ManageLockGroups: React.FC<Props> = ({
  handleOpenCreateLockGroupModal,
  handleOnDeleteLockGroupIconClick,
  handleOpenEditLockGroupModal,
  doorGroups,
  searchGroupInputValue,
  searchGroupInputOnChange
}) => (
  <ComponentWrapper flexDirection="column" rowGap="0.75rem">
    <ComponentWrapper>
      <Button onClick={handleOpenCreateLockGroupModal} id="drawer-create-lock-group-button">
        Create lock group
      </Button>
    </ComponentWrapper>
    <ComponentWrapper flexDirection="column">
      <Input
        label="Search group"
        value={searchGroupInputValue}
        onChange={(event) => searchGroupInputOnChange(event.target.value)}
        id="drawer-search-group-input"
      />
    </ComponentWrapper>
    <ComponentWrapper flexDirection="column">
      <Label>Existing lock groups</Label>
      <LockGroupWrapper>
        {doorGroups.length === 0 && searchGroupInputValue === undefined && (
          <LockGroup id="lock-group-no-groups-created" lockGroupName="No groups created" displayIcon={false} />
        )}
        {doorGroups.map((group, index) => (
          <LockGroup
            id={`lock-group-${index}`}
            deleteIconId={`lock-group-delete-icon-${index}`}
            lockGroupName={group.name}
            numberOfSelectedDoors={group.doors.length}
            isSelected={false}
            onDeleteClickIcon={() => handleOnDeleteLockGroupIconClick(group.id)}
            onClick={() => handleOpenEditLockGroupModal(group.id, group.name, group.doors)}
          />
        ))}
        {doorGroups.length === 0 && searchGroupInputValue !== undefined && (
          <ComponentWrapper justifyContent="center">
            <Typography variant="body4" color="dTextMedium">
              No results
            </Typography>
          </ComponentWrapper>
        )}
      </LockGroupWrapper>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default ManageLockGroups;
