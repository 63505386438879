/* eslint-disable import/prefer-default-export */

import { OnOfficeDayDeleted_onOfficeDayDeleted } from 'graphql/generated/OnOfficeDayDeleted';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedSubscriptionOfficeDayDeleted({ date }: Props): OnOfficeDayDeleted_onOfficeDayDeleted {
  return {
    __typename: 'SubscriptionOfficeDay',
    date
  };
}
