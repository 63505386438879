/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithSchedules,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { createMockedAccessGrantWeekSchedule } from 'mocks/objectsBaseOnSchemaType/accessGrantWeekSchedule';
import { createMockedAccessGrantWeekScheduleDay } from 'mocks/objectsBaseOnSchemaType/accessGrantWeekScheduleDay';
import { createMockedAccessGrantWithSchedules } from 'mocks/objectsBaseOnSchemaType/accessGrantWithSchedules';
import { createMockedVisitorGroupWithSchedules } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithSchedules';

export function mockedGetVisitorGroupsForTenantWithSchedulesData(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithSchedules {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsWithSchedulesForTenantDataForQuery =
  mockedGetVisitorGroupsForTenantWithSchedulesData([
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules',
      name: 'mockedVisitorGroupNameWithSchedules',
      defaultAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [createMockedAccessGrantWeekScheduleDay({ dayName: 'monday', from: '07:00', to: '18:00' })]
          })
        })
      ]
    }),
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules1',
      name: 'mockedVisitorGroupNameWithSchedules1',
      defaultAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays1',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [
              createMockedAccessGrantWeekScheduleDay({ dayName: 'monday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'tuesday', from: '07:00', to: '18:00' })
            ]
          })
        })
      ]
    }),
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules2',
      name: 'mockedVisitorGroupNameWithSchedules2',
      defaultAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays2',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [
              createMockedAccessGrantWeekScheduleDay({ dayName: 'monday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'wednesday', from: '09:00', to: '17:00' })
            ]
          })
        })
      ]
    }),
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules3',
      name: 'mockedVisitorGroupNameWithSchedules3',
      defaultAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays3',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [
              createMockedAccessGrantWeekScheduleDay({ dayName: 'monday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'tuesday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'wednesday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'thursday', from: '07:00', to: '18:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'friday', from: '07:00', to: '18:00' })
            ]
          })
        })
      ]
    }),
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules4',
      name: 'mockedVisitorGroupNameWithSchedules4',
      defaultAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays4',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [
              createMockedAccessGrantWeekScheduleDay({ dayName: 'sunday', from: '07:00', to: '14:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'saturday', from: '07:00', to: '14:00' })
            ]
          })
        })
      ]
    }),
    createMockedVisitorGroupWithSchedules({
      id: 'mockedVisitorGroupIdWithSchedules5',
      name: 'mockedVisitorGroupNameWithSchedules5',
      scheduledAccessGrants: [
        createMockedAccessGrantWithSchedules({
          id: 'mockedAccessGrantWithDays5',
          accessSchedule: createMockedAccessGrantWeekSchedule({
            weekDays: [
              createMockedAccessGrantWeekScheduleDay({ dayName: 'sunday', from: '07:00', to: '14:00' }),
              createMockedAccessGrantWeekScheduleDay({ dayName: 'saturday', from: '07:00', to: '14:00' })
            ]
          })
        })
      ]
    })
  ]);
