import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { MockedProvider } from '@apollo/client/testing';
import getApolloClient, { cache } from 'environment/ApolloClientVMS';
import useToken from 'hooks/useToken/useToken';
import mocks from 'mocks/graphqlApollo/mocks';

const Apollo: React.FC = ({ children }) => {
  const { getToken } = useToken();
  const apolloClient = getApolloClient(getToken);

  if (process.env.REACT_APP_MODE === 'mocked') {
    return (
      <MockedProvider mocks={mocks} cache={cache}>
        {children}
      </MockedProvider>
    );
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Apollo;
