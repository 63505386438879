import React, { useCallback, useEffect, useState } from 'react';
import Search from 'ui/molecules/Search/Search';
import styled from 'styled-components';
import Drawer from 'components/Drawer/Drawer';
import PageContent from 'ui/templates/PageContent/PageContent';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import { VisitorsGroupsState } from 'state/types';
import ArrayUtil from 'utils/Array/Array.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useVisitorsGroups from 'hooks/useVisitorsGroups/useVisitorsGroups';
import useSortingUserGroupsTable from 'hooks/useSorting/useSortingAccessSettingsTable/useSortingAccessSettingsTable';
import { GetVisitorGroupsForTenant_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenant';
import getVisitorGroupIcon, { VisitorGroupType } from 'utils/VisitorGroupIcon/VisitorGroupIcon.util';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import useIsOfficeModeEnabled from 'hooks/useIsOfficeModeEnabled/useIsOfficeModeEnabled';
import { accessTabs } from '../accessTabsConst';

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

export const headerAccessSettingsLeftTable = ['Groups'] as const;
export const headerAccessSettingsRightTable = ['Access settings'] as const;

const AccessSettings = (): JSX.Element => {
  const [filterStr, setFilterStr] = useState('');
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    visitorsGroups,
    loading: visitorGroupsLoading,
    setIsScheduledAccessGrantsEnabled,
    setAccessGrantsForGroupsMutationLoading
  } = useVisitorsGroups({ handleFetchError });
  const {
    checkIsOfficeModeEnabledForVisitorGroup,
    loading: visitorGroupsWithIsOfficeModeEnabledLoading,
    updateAccessGrantIsOfficeModeEnabled,
    updateAccessGrantIsOfficeModeEnabledMutationLoading
  } = useIsOfficeModeEnabled({ handleFetchError });
  const skeletonArray = ArrayUtil.SkeletonArray();
  const [leftTableSelectedRowGroup, setLeftTableSelectedRowGroup] =
    useState<GetVisitorGroupsForTenant_getVisitorGroupsForTenant>();
  const { handleSortingOnClick, handleSortingIcon } = useSortingUserGroupsTable();

  const getFilterData = useCallback((): VisitorsGroupsState => {
    if (filterStr !== '')
      return visitorsGroups.filter((element) => element.name.toLowerCase().includes(filterStr.toLowerCase()));
    return visitorsGroups;
  }, [filterStr, visitorsGroups]);

  useEffect(() => {
    if (
      filterStr &&
      leftTableSelectedRowGroup &&
      getFilterData().find((item) => item === leftTableSelectedRowGroup) === undefined
    )
      setLeftTableSelectedRowGroup(undefined);
  }, [filterStr, getFilterData, leftTableSelectedRowGroup]);

  const handleLeftTableOnRowClick = (group: GetVisitorGroupsForTenant_getVisitorGroupsForTenant) => {
    if (leftTableSelectedRowGroup?.externalRef !== group.externalRef) setLeftTableSelectedRowGroup(group);
    else setLeftTableSelectedRowGroup(undefined);
  };

  const handleRightTableOnOfficeModeClick = () => {
    if (!updateAccessGrantIsOfficeModeEnabledMutationLoading && leftTableSelectedRowGroup)
      updateAccessGrantIsOfficeModeEnabled(leftTableSelectedRowGroup.id);
  };

  const handleRightTableOnAccessByReservationClick = async () => {
    if (!setAccessGrantsForGroupsMutationLoading && leftTableSelectedRowGroup !== undefined) {
      const updatedGroup = await setIsScheduledAccessGrantsEnabled(
        leftTableSelectedRowGroup.id,
        Boolean(
          leftTableSelectedRowGroup.defaultAccessGrants.length > leftTableSelectedRowGroup.scheduledAccessGrants.length
        )
      );
      if (updatedGroup) setLeftTableSelectedRowGroup(updatedGroup.setAccessGrantsForGroups);
    }
  };

  const getIsAdGroup = (userGroup: GetVisitorGroupsForTenant_getVisitorGroupsForTenant | undefined) =>
    Boolean(
      !(
        userGroup?.type === 'EXTERNAL_VISITORS' ||
        userGroup?.type === 'CO_CONTRACTORS' ||
        userGroup?.type === 'INSTALLERS'
      )
    );

  return (
    <PageContent title="ATLAS" titleId="location" tabs={accessTabs}>
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <Search onChange={(event) => setFilterStr(event.target.value)} value={filterStr} />
          </SearchWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <ComponentWrapper width="100%" height="calc(100vh - 18rem)" margin="0 1rem 0 0" gap="1rem">
        <Table
          header={
            <TableHeaderRow placeForAvatarOrSwitchOrCheckbox placeForArrow>
              <TableHeader
                headerText={headerAccessSettingsLeftTable[0]}
                id={`header-${headerAccessSettingsLeftTable[0].toLocaleLowerCase()}`}
                flex="0 0 100%"
                iconSorting={handleSortingIcon(headerAccessSettingsLeftTable[0])}
                onClick={() => handleSortingOnClick(headerAccessSettingsLeftTable[0])}
              />
            </TableHeaderRow>
          }
          headerId="ad-groups-access-settings-header"
          rowsWrapperId="ad-groups-access-settings-list"
        >
          {visitorGroupsLoading || visitorGroupsWithIsOfficeModeEnabledLoading
            ? skeletonArray.map((_, index) => (
                <TableRow
                  beforeSwitchSkeleton
                  id={`row-skeleton-${index}`}
                  marginAfterContent
                  key={`skeletonTableLeftColumnRow-${_.id}`}
                >
                  <TableCell isLoading firstLineText="" flex="0 0 100%" />
                </TableRow>
              ))
            : getFilterData().map((userGroup, id) => (
                <TableRow
                  hoverEffect
                  beforeContentIconProps={{
                    name: getVisitorGroupIcon.getVisitorGroupIcon(userGroup.type as VisitorGroupType),
                    width: 24,
                    height: 24,
                    viewBox: '0 0 32 32',
                    color: 'transparent',
                    stroke: 'lTextHigh'
                  }}
                  id={`row-${id}`}
                  key={userGroup.id}
                  onClick={() => handleLeftTableOnRowClick(userGroup)}
                  selected={userGroup.externalRef === leftTableSelectedRowGroup?.externalRef}
                >
                  <TableCell firstLineText={userGroup.name} firstLineId={`row-${id}-name`} flex="0 0 100%" />
                </TableRow>
              ))}
        </Table>
        <Table
          header={
            <TableHeaderRow>
              <TableHeader
                headerText={headerAccessSettingsRightTable[0]}
                id={`header-${headerAccessSettingsRightTable[0].toLocaleLowerCase()}`}
                flex="0 0 100%"
              />
            </TableHeaderRow>
          }
          headerId="permission-header"
          rowsWrapperId="permission-list"
        >
          {visitorGroupsLoading || visitorGroupsWithIsOfficeModeEnabledLoading ? (
            <>
              <TableRow id="row-skeleton-0" marginAfterContent key="skeletonTableRightColumnRow-0">
                <TableCell isLoading firstLineText="" flex="0 0 100%" />
              </TableRow>
              <TableRow id="row-skeleton-1" marginAfterContent key="skeletonTableRightColumnRow-1">
                <TableCell isLoading firstLineText="" flex="0 0 100%" />
              </TableRow>
            </>
          ) : (
            <>
              <TableRow
                id="row-permission-office-mode"
                key="row-permission-office-mode"
                afterSwitchProps={{
                  checked:
                    leftTableSelectedRowGroup && checkIsOfficeModeEnabledForVisitorGroup(leftTableSelectedRowGroup.id),
                  disabled: leftTableSelectedRowGroup === undefined
                }}
                onClick={handleRightTableOnOfficeModeClick}
                marginAfterContent
                disabled={leftTableSelectedRowGroup === undefined}
              >
                <TableCell
                  firstLineText="Allow office mode"
                  firstLineId="row-permission-office-mode-firstLine"
                  secondLineText="Allow users to enable Office Mode on available locks."
                  secondLineId="row-permission-office-mode-second-line"
                  flex="0 0 100%"
                />
              </TableRow>
              <TableRow
                id="row-permission-access-by-reservation"
                key="row-permission-access-by-reservation"
                afterSwitchProps={{
                  checked:
                    leftTableSelectedRowGroup &&
                    leftTableSelectedRowGroup.scheduledAccessGrants.length <
                      leftTableSelectedRowGroup.defaultAccessGrants.length,
                  disabled:
                    !getIsAdGroup(leftTableSelectedRowGroup) || leftTableSelectedRowGroup?.externalRef === undefined
                }}
                onClick={
                  !getIsAdGroup(leftTableSelectedRowGroup) || leftTableSelectedRowGroup?.externalRef === undefined
                    ? undefined
                    : handleRightTableOnAccessByReservationClick
                }
                marginAfterContent
                disabled={
                  !getIsAdGroup(leftTableSelectedRowGroup) || leftTableSelectedRowGroup?.externalRef === undefined
                }
              >
                <TableCell
                  firstLineText="ATLAS reservation required"
                  firstLineId="row-permission-access-by-reservation-firstLine"
                  secondLineText="Require users to make a reservation in the ATLAS Portal to get access during scheduled time."
                  secondLineId="row-permission-access-by-reservation-second-line"
                  flex="0 0 100%"
                />
              </TableRow>
            </>
          )}
        </Table>
      </ComponentWrapper>
      <Drawer />
    </PageContent>
  );
};

export default AccessSettings;
