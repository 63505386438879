import { useMutation } from '@apollo/client';
import { UnregisterMobileDevice, UnregisterMobileDeviceVariables } from 'graphql/generated/UnregisterMobileDevice';
import {
  UnregisterMobileDeviceById,
  UnregisterMobileDeviceByIdVariables
} from 'graphql/generated/UnregisterMobileDeviceById';
import { UNREGISTER_MOBILE_DEVICE, UNREGISTER_MOBILE_DEVICE_BY_ID } from 'graphql/mutations';
import { BaseHookProps } from 'hooks/shared/types';

const useUnregisterMobileDevice = ({ handleFetchError }: BaseHookProps) => {
  const [unregisterMobileDeviceMutation, { loading: unregisterMobileDeviceMutationLoading }] = useMutation<
    UnregisterMobileDevice,
    UnregisterMobileDeviceVariables
  >(UNREGISTER_MOBILE_DEVICE, {
    onError: () => handleFetchError('Error while unregister Mobile Device')
  });

  const unregisterMobileDevice = async (email: string, mobileDeviceId: string) => {
    const { data } = await unregisterMobileDeviceMutation({
      variables: {
        email,
        mobileDeviceId
      }
    });

    return data;
  };

  const [unregisterMobileDeviceByIdMutation, { loading: unregisterMobileDeviceByIdMutationLoading }] = useMutation<
    UnregisterMobileDeviceById,
    UnregisterMobileDeviceByIdVariables
  >(UNREGISTER_MOBILE_DEVICE_BY_ID, {
    onError: () => handleFetchError('Error while unregister Mobile Device')
  });

  const unregisterMobileDeviceById = async (visitorId: string, mobileDeviceId: string) => {
    const { data } = await unregisterMobileDeviceByIdMutation({
      variables: {
        visitorId,
        mobileDeviceId
      }
    });

    return data;
  };

  return {
    unregisterMobileDevice,
    unregisterMobileDeviceMutationLoading,
    unregisterMobileDeviceById,
    unregisterMobileDeviceByIdMutationLoading
  };
};

export default useUnregisterMobileDevice;
