/* eslint-disable no-magic-numbers */
import React from 'react';
import styled, { css } from 'styled-components';
import * as CSS from 'csstype';
import { Colors } from 'theme/theme';

export type TypographyVariant =
  | 'loginTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'body'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'button'
  | 'super'
  | 'clickable'
  | 'label'
  | 'helper';

export interface Props {
  variant?: TypographyVariant;
  color?: keyof Colors;
  opacity?: string;
  whiteSpace?: string;
  textOverflow?: string;
  overflow?: string;
  id?: string;
}

export const calculateDetails = (size: number, height: number, fontWeight: CSS.Property.FontWeight = 'normal') => css`
  font-size: ${size}rem;
  line-height: ${height}rem;
  font-weight: ${fontWeight};
`;

export const handleVariantDetails = (variant: TypographyVariant) => {
  switch (variant) {
    case 'loginTitle':
      return calculateDetails(4, 6.75, 500);
    case 'title1':
      return calculateDetails(2, 3, 500);
    case 'title2':
      return calculateDetails(1.5, 2.25, 500);
    case 'title3':
      return calculateDetails(1.5, 2.25, 300);
    case 'title4':
      return calculateDetails(1.125, 1.6875, 500);
    case 'body1':
      return calculateDetails(1.125, 1.6875);
    case 'body2':
      return calculateDetails(1, 1.5);
    case 'body3':
      return calculateDetails(0.875, 1.3125);
    case 'body4':
      return calculateDetails(0.75, 1.125);
    case 'button':
      return calculateDetails(1, 1.5, 600);
    case 'super':
      return calculateDetails(0.625, 0.9375);
    case 'clickable':
      return calculateDetails(0.875, 1.3125);
    case 'label':
    case 'helper':
      return calculateDetails(0.5, 0.75, 600);
    case 'body':
    default:
      return calculateDetails(1.125, 1.6875);
  }
};

const TypographyBase = css<Required<Props>>`
  font-style: normal;
  margin: 0;
  text-overflow: ${(props) => props.textOverflow};
  overflow: ${(props) => props.overflow};
  color: ${(props) => props.theme.colors[props.color]};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  ${(props) => handleVariantDetails(props.variant)}
`;

const StyledLoginTitle = styled.h1`
  ${TypographyBase};
  text-align: center;
`;

const StyledHeader = styled.h1`
  ${TypographyBase};
`;

const StyledSubHeader = styled.h2`
  ${TypographyBase};
`;

const StyledParagraph = styled.p`
  ${TypographyBase};
`;

const StyledBodyVariant = styled.p`
  ${TypographyBase};
  letter-spacing: 0.05em;
`;

const StyledButtonText = styled.span`
  ${TypographyBase};
`;

const StyledSuper = styled.sup`
  ${TypographyBase};
`;

const StyledClickable = styled.div`
  ${TypographyBase};
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  ${TypographyBase};
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const StyledHelper = styled.span`
  ${TypographyBase};
  letter-spacing: 0.15em;
`;

const Typography: React.FC<Props> = ({
  variant = 'body',
  color = 'lTextHigh',
  opacity = '1',
  whiteSpace = 'normal',
  textOverflow = 'ellipsis',
  overflow = 'hidden',
  id = '',
  children,
  ...rest
}) => {
  const props = {
    ...rest,
    variant,
    color,
    opacity,
    whiteSpace,
    textOverflow,
    overflow,
    id
  };
  return (
    <>
      {
        {
          loginTitle: <StyledLoginTitle {...props}>{children}</StyledLoginTitle>,
          title1: <StyledHeader {...props}>{children}</StyledHeader>,
          title2: <StyledHeader {...props}>{children}</StyledHeader>,
          title3: <StyledHeader {...props}>{children}</StyledHeader>,
          title4: <StyledSubHeader {...props}>{children}</StyledSubHeader>,
          body: <StyledParagraph {...props}>{children}</StyledParagraph>,
          body1: <StyledBodyVariant {...props}>{children}</StyledBodyVariant>,
          body2: <StyledBodyVariant {...props}>{children}</StyledBodyVariant>,
          body3: <StyledBodyVariant {...props}>{children}</StyledBodyVariant>,
          body4: <StyledBodyVariant {...props}>{children}</StyledBodyVariant>,
          button: <StyledButtonText {...props}>{children}</StyledButtonText>,
          super: <StyledSuper {...props}>{children}</StyledSuper>,
          clickable: (
            <StyledClickable {...props} role="button" tabIndex={0}>
              {children}
            </StyledClickable>
          ),
          label: <StyledLabel {...props}>{children}</StyledLabel>,
          helper: <StyledHelper {...props}>{children}</StyledHelper>
        }[variant]
      }
    </>
  );
};

export default Typography;
