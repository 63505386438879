/* eslint-disable import/prefer-default-export */

import { GetOfficeDays, GetOfficeDays_getOfficeDays } from 'graphql/generated/GetOfficeDays';
import { createMockedOfficeDay } from 'mocks/objectsBaseOnSchemaType/officeDay';
import { VISIT_TO_ADD_DATE_START } from '../dateUsedInMocks';

export function mockedGetOfficeDays(getOfficeDays: GetOfficeDays_getOfficeDays[]): GetOfficeDays {
  return {
    getOfficeDays
  };
}

export const mockedGetOfficeDaysDataForQuery = mockedGetOfficeDays([
  createMockedOfficeDay({ date: VISIT_TO_ADD_DATE_START })
]);
