import { isEqual, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { headerLeftTable } from 'pages/Permissions/Permissions/Permissions';
import { useReactiveVar } from '@apollo/client';
import { permissionsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';
import { PermissionsState } from 'state/types';
import { setPermissionsGroups } from 'state/actions/permissions';

interface Sorting {
  isAscending?: boolean;
  columnName?: typeof headerLeftTable[number];
}

export const handleSorting = (
  item: GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant,
  columnName?: typeof headerLeftTable[number]
) => {
  switch (columnName) {
    case 'AD groups':
    default:
      return item.name;
  }
};

export const getSortedArray = (coContractorsGroups: PermissionsState, sorting: Sorting) =>
  orderBy(coContractorsGroups, (item) => handleSorting(item, sorting.columnName as typeof headerLeftTable[number]), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

const useSortingPermissionsTable = () => {
  const permissionGroups = useReactiveVar(permissionsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: 'AD groups' });

  const handleSortingOnClick = (columnName: typeof headerLeftTable[number]) => {
    if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
      return setSorting({ isAscending: !sorting.isAscending, columnName });
    }
    return setSorting({ isAscending: true, columnName });
  };

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedPermissionGroups = getSortedArray(permissionGroups, sorting);
    if (!isEqual(sortedPermissionGroups, permissionGroups)) setPermissionsGroups(sortedPermissionGroups);
  }, [permissionGroups, sorting]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingPermissionsTable;
