import { GET_OFFICE_DAYS, GET_AVAILABLE_OFFICE_DAYS } from 'graphql/queries';
import { useEffect, useMemo } from 'react';
import { useReactiveVar, useLazyQuery, useMutation } from '@apollo/client';
import { authVar, officeDaysVar, officeAvailableDaysVar } from 'state/vars';
import dayjs from 'dayjs';
import { ADD_OFFICE_DAY, DELETE_OFFICE_DAY } from 'graphql/mutations';
import { OnOfficeDayAdded, OnOfficeDayAddedVariables } from 'graphql/generated/OnOfficeDayAdded';
import {
  ON_OFFICE_DAY_ADDED,
  ON_OFFICE_DAY_ADDED_BY_ID,
  ON_OFFICE_DAY_DELETED,
  ON_OFFICE_DAY_DELETED_BY_ID
} from 'graphql/subscriptions';
import {
  addOfficeDaysAction,
  removeOfficeDaysAction,
  setOfficeDaysAction,
  setOfficeAvailableDaysAction
} from 'state/actions/officeDays';
import { OnOfficeDayDeleted, OnOfficeDayDeletedVariables } from 'graphql/generated/OnOfficeDayDeleted';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { getUniqueArray } from 'utils/Array/Array.util';
import { GetAvailableOfficeDays, GetAvailableOfficeDaysVariables } from 'graphql/generated/GetAvailableOfficeDays';
import isBetween from 'dayjs/plugin/isBetween';
import { AddOfficeDay, AddOfficeDayVariables } from 'graphql/generated/AddOfficeDay';
import { DeleteOfficeDay, DeleteOfficeDayVariables } from 'graphql/generated/DeleteOfficeDay';
import { GetOfficeDays, GetOfficeDaysVariables } from 'graphql/generated/GetOfficeDays';
import { OnOfficeDayAddedById, OnOfficeDayAddedByIdVariables } from 'graphql/generated/OnOfficeDayAddedById';
import { OnOfficeDayDeletedById, OnOfficeDayDeletedByIdVariables } from 'graphql/generated/OnOfficeDayDeletedById';
import { BaseHookProps } from '../shared/types';

dayjs.extend(isBetween);

const isNotUseEmailAsPrimaryIdentifierEnable =
  process.env.REACT_APP_FEATURE_NOT_USE_EMAIL_AS_PRIMARY_IDENTIFIER === 'true';

const useOfficeDays = ({ handleFetchError }: BaseHookProps) => {
  const officeDays = useReactiveVar(officeDaysVar);
  const officeAvailableDays = useReactiveVar(officeAvailableDaysVar);
  const { user } = useReactiveVar(authVar);
  const { email, id } = user!;
  const filter = useMemo(
    () => ({
      dateRange: {
        rangeStart: getDateWithFormat('AWSDateTime', dayjs().year(), dayjs().month(), dayjs().startOf('month').date()),
        rangeEnd: getDateWithFormat(
          'AWSDateTime',
          dayjs().year(),
          dayjs().add(1, 'month').month(),
          dayjs().endOf('month').date()
        )
      }
    }),
    []
  );

  const [getOfficeDays, { loading: officeDaysLoading, subscribeToMore }] = useLazyQuery<
    GetOfficeDays,
    GetOfficeDaysVariables
  >(GET_OFFICE_DAYS, {
    onCompleted: (data) => {
      setOfficeDaysAction(data.getOfficeDays);
    },
    onError: () => {
      handleFetchError('Error while fetching visits');
    }
  });

  useEffect(() => {
    getOfficeDays({
      variables: {
        filter
      }
    });
  }, [filter, getOfficeDays]);

  useEffect(() => {
    if (subscribeToMore && email && !isNotUseEmailAsPrimaryIdentifierEnable) {
      subscribeToMore<OnOfficeDayAdded, OnOfficeDayAddedVariables>({
        document: ON_OFFICE_DAY_ADDED,
        variables: {
          email
        },
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data.onOfficeDayAdded) {
            return prev;
          }
          const newVisit = data.onOfficeDayAdded;
          addOfficeDaysAction(newVisit);
          return {
            getOfficeDays: getUniqueArray(prev.getOfficeDays, [
              ...prev.getOfficeDays,
              { ...newVisit, __typename: 'OfficeDay' }
            ])
          };
        }
      });
      subscribeToMore<OnOfficeDayDeleted, OnOfficeDayDeletedVariables>({
        document: ON_OFFICE_DAY_DELETED,
        variables: {
          email
        },
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data.onOfficeDayDeleted) {
            return prev;
          }
          const removedVisit = data.onOfficeDayDeleted;
          removeOfficeDaysAction(removedVisit.date);
          return {
            getOfficeDays: getUniqueArray(prev.getOfficeDays, [
              ...prev.getOfficeDays.filter((visit) => !dayjs(visit.date).isSame(dayjs(removedVisit.date), 'date'))
            ])
          };
        }
      });
    }
  }, [email, subscribeToMore]);

  useEffect(() => {
    if (subscribeToMore && id && !isNotUseEmailAsPrimaryIdentifierEnable) {
      subscribeToMore<OnOfficeDayAddedById, OnOfficeDayAddedByIdVariables>({
        document: ON_OFFICE_DAY_ADDED_BY_ID,
        variables: {
          visitorId: id
        },
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data.onOfficeDayAddedById) {
            return prev;
          }
          const newVisit = data.onOfficeDayAddedById;
          addOfficeDaysAction(newVisit);
          return {
            getOfficeDays: getUniqueArray(prev.getOfficeDays, [
              ...prev.getOfficeDays,
              { ...newVisit, __typename: 'OfficeDay' }
            ])
          };
        }
      });
      subscribeToMore<OnOfficeDayDeletedById, OnOfficeDayDeletedByIdVariables>({
        document: ON_OFFICE_DAY_DELETED_BY_ID,
        variables: {
          visitorId: id
        },
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data.onOfficeDayDeletedById) {
            return prev;
          }
          const removedVisit = data.onOfficeDayDeletedById;
          removeOfficeDaysAction(removedVisit.date);
          return {
            getOfficeDays: getUniqueArray(prev.getOfficeDays, [
              ...prev.getOfficeDays.filter((visit) => !dayjs(visit.date).isSame(dayjs(removedVisit.date), 'date'))
            ])
          };
        }
      });
    }
  }, [id, subscribeToMore]);

  const [addOfficeDayMutation, { loading: addOfficeDayLoading }] = useMutation<AddOfficeDay, AddOfficeDayVariables>(
    ADD_OFFICE_DAY,
    {
      onCompleted: (data) => {
        addOfficeDaysAction(data.addOfficeDay);
      },
      onError: (error) => {
        if (error.message === 'No doors or location are assigned to your Visitor Group')
          handleFetchError(`Unable to book your visit. No access has been assigned to you yet.`);
        else handleFetchError(`Error while adding visit: ${error.message}`);
      }
    }
  );

  const addOfficeDay = async (date: dayjs.Dayjs) => {
    const { data } = await addOfficeDayMutation({
      variables: {
        input: {
          date: getDateWithFormat('AWSDate', date.year(), date.month(), date.date())
        }
      },
      update(cache, { data: addedVisit }) {
        cache.writeQuery({
          query: GET_OFFICE_DAYS,
          variables: {
            filter
          },
          data: {
            getOfficeDays: [...officeDays, addedVisit?.addOfficeDay]
          }
        });
      }
    });
    return data;
  };

  const [deleteOfficeDayMutation, { loading: deleteOfficeDayLoading }] = useMutation<
    DeleteOfficeDay,
    DeleteOfficeDayVariables
  >(DELETE_OFFICE_DAY, {
    onCompleted: (data) => {
      removeOfficeDaysAction(data.deleteOfficeDay);
    },
    onError: () => handleFetchError('Error while removing visit')
  });

  const deleteOfficeDayVisit = async (date: dayjs.Dayjs) => {
    const { data } = await deleteOfficeDayMutation({
      variables: {
        date: getDateWithFormat('AWSDate', date.year(), date.month(), date.date())
      },
      update(cache, { data: removedVisitId }) {
        const updatedVisit = officeDays.filter(
          (visit) => !dayjs(visit.date).isSame(dayjs(removedVisitId?.deleteOfficeDay), 'date')
        );
        cache.writeQuery({
          query: GET_OFFICE_DAYS,
          variables: {
            filter
          },
          data: {
            getOfficeDays: updatedVisit
          }
        });
      }
    });
    return data;
  };

  const [getAvailableOfficeDays, { loading: officeAvailableDaysLoading }] = useLazyQuery<
    GetAvailableOfficeDays,
    GetAvailableOfficeDaysVariables
  >(GET_AVAILABLE_OFFICE_DAYS, {
    onCompleted: (data) => {
      setOfficeAvailableDaysAction(data.getAvailableOfficeDays);
    },
    onError: () => {
      handleFetchError('Error while fetching available days');
    }
  });

  useEffect(() => {
    getAvailableOfficeDays({
      variables: {
        from: dayjs().startOf('month').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')
      }
    });
  }, [getAvailableOfficeDays]);

  return {
    officeDays,
    officeDaysLoading,
    addOfficeDay,
    addOfficeDayLoading,
    deleteOfficeDayVisit,
    deleteOfficeDayLoading,
    officeAvailableDays,
    officeAvailableDaysLoading
  };
};

export default useOfficeDays;
