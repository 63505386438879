/* eslint-disable import/prefer-default-export */

import { AddVisitorWithRole, AddVisitorWithRole_addVisitor } from 'graphql/generated/AddVisitorWithRole';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function mockedAddVisitorWithRoleData(addVisitor: AddVisitorWithRole_addVisitor): AddVisitorWithRole {
  return {
    addVisitor
  };
}

export const mockedAddVisitorWithRoleDataForMutation = mockedAddVisitorWithRoleData(
  createMockedVisitorWithVisitorGroupMembership({
    id: 'mockedVisitorIdCoContractorNew',
    name: 'mockedVisitorNameCoContractorNew',
    email: 'mockedVisitorEmailCoContractorNew'
  })
);
