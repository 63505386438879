/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import { OnOfficeDayAdded, OnOfficeDayAdded_onOfficeDayAdded } from 'graphql/generated/OnOfficeDayAdded';
import { createMockedSubscriptionOfficeDayAdded } from 'mocks/objectsBaseOnSchemaType/subscriptionOfficeDayAdded';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { firstDate } from '../dateUsedInMocks';

export function mockedOnOfficeDayAddedData(
  onOfficeDayAdded: OnOfficeDayAdded_onOfficeDayAdded | null
): OnOfficeDayAdded {
  return {
    onOfficeDayAdded
  };
}

export const mockedOnOfficeDayAddedDataForSubscription = mockedOnOfficeDayAddedData(
  createMockedSubscriptionOfficeDayAdded({
    date: getDateWithFormat(
      'AWSDate',
      dayjs(firstDate).year(),
      dayjs(firstDate).month(),
      dayjs(firstDate).startOf('month').date()
    )
  })
);
