/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithMembers,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { createMockedMobileDevice } from 'mocks/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitor } from 'mocks/objectsBaseOnSchemaType/visitor';
import { createMockedVisitorGroupWithMembersAndType } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithMembersAndTypeCoContractors';

export function getVisitorGroupsForTenantWithMembersAndTypesDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithMembers {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdCoContractors1',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor1',
          name: 'mockedVisitorNameCoContractor1',
          email: 'mockedVisitorEmailCoContractor1'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor2',
          name: 'mockedVisitorNameCoContractor2',
          email: 'mockedVisitorEmailCoContractor2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        })
      ],
      name: 'co-contractors1',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdCoContractors2',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor3',
          name: 'mockedVisitorNameCoContractor3',
          email: 'mockedVisitorEmailCoContractor3'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor4',
          name: 'mockedVisitorNameCoContractor4',
          email: 'mockedVisitorEmailCoContractor4',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor5',
          name: 'mockedVisitorNameCoContractor5',
          email: 'mockedVisitorEmailCoContractor5',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        })
      ],
      name: 'co-contractors2',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs1'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdCoContractors3',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdCoContractor6',
          name: 'mockedVisitorNameCoContractor6',
          email: 'mockedVisitorEmailCoContractor6'
        })
      ],
      name: 'co-contractors3',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs2'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdCoContractors4',
      name: 'co-contractors4',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs3'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQueryWithoutFilters =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupId1WithoutFilters',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorId1WithoutFilters',
          name: 'mockedVisitorName1WithoutFilters',
          email: 'mockedVisitorEmail1WithoutFilters'
        }),
        createMockedVisitor({
          id: 'mockedVisitorId2WithoutFilters',
          name: 'mockedVisitorName2WithoutFilters',
          email: 'mockedVisitorEmail2WithoutFilters',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
        })
      ],
      name: 'mockedVisitorGroupWithoutFilters',
      type: 'AZURE_AD',
      externalRef: 'mockedVisitorGroupExternalRefsWithoutFilters'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery1 =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdInstallers1',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdInstaller1',
          name: 'mockedVisitorNameInstaller1',
          email: 'mockedVisitorEmailInstaller1'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdInstaller2',
          name: 'mockedVisitorNameInstaller2',
          email: 'mockedVisitorEmailInstaller2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
        })
      ],
      name: 'installers1',
      type: 'INSTALLERS',
      externalRef: 'mockedVisitorGroupExternalRefsInstallers'
    })
  ]);
