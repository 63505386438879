import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  GetVisitorGroupsForTenantWithIsAdminGroup,
  GetVisitorGroupsForTenantWithIsAdminGroupVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';
import { GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP } from 'graphql/queries';
import { SetIsAdminForGroup, SetIsAdminForGroupVariables } from 'graphql/generated/SetIsAdminForGroup';
import { SET_IS_ADMIN_FOR_GROUP } from 'graphql/mutations';
import { permissionsVar } from 'state/vars';
import { setIsAdminGroupAction, setPermissionsGroups } from 'state/actions/permissions';
import { BaseHookProps } from '../shared/types';

const usePermissions = ({ handleFetchError }: BaseHookProps) => {
  const groupsWithPermissions = useReactiveVar(permissionsVar);

  const [getGroupsWithPermissions, { loading }] = useLazyQuery<
    GetVisitorGroupsForTenantWithIsAdminGroup,
    GetVisitorGroupsForTenantWithIsAdminGroupVariables
  >(GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP, {
    onCompleted: (data) => {
      setPermissionsGroups(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching installers');
    }
  });

  useEffect(() => {
    getGroupsWithPermissions({
      variables: {
        filter: { type: 'AZURE_AD' }
      }
    });
  }, [getGroupsWithPermissions]);

  const [setIsAdminGroupMutation, { loading: setIsAdminGroupLoading }] = useMutation<
    SetIsAdminForGroup,
    SetIsAdminForGroupVariables
  >(SET_IS_ADMIN_FOR_GROUP, {
    onCompleted: (data) => {
      setIsAdminGroupAction(data.setIsAdminForGroup);
    },
    onError: () => {
      handleFetchError('Error while setting group admin');
    }
  });

  const setIsAdminGroup = async (externalRef: string, isAdmin: boolean) => {
    const { data } = await setIsAdminGroupMutation({
      variables: { externalRef, isAdmin },
      update(cache, { data: setIsAdminGroupData }) {
        const updatedGroups = groupsWithPermissions.map((group) => {
          if (group.externalRef === setIsAdminGroupData?.setIsAdminForGroup)
            return {
              ...group,
              isAdminGroup: isAdmin
            };
          return group;
        });

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP,
          variables: {
            filter: { type: 'AZURE_AD' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  return {
    groupsWithPermissions,
    loading,
    setIsAdminGroup,
    setIsAdminGroupLoading
  };
};

export default usePermissions;
