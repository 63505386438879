import React from 'react';
import styled from 'styled-components';
import { WidthOrHeight } from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface CssProps {
  flex?: string;
  width?: WidthOrHeight;
}

export type Props = CssProps & {
  id?: string;
};

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ width }) => width && `width:${width};`};
`;

const TableCellEmpty: React.FC<Props> = ({ flex = 'auto', width, id }) => (
  <TableCellWrapper flex={flex} width={width} id={id} />
);

export default TableCellEmpty;
