/* eslint-disable import/prefer-default-export */

import { UpdateLocation, UpdateLocation_updateLocation } from 'graphql/generated/UpdateLocation';
import { createLocationWithDoors } from 'mocks/objectsBaseOnSchemaType/locationWithDoors';

export function mockedUpdateLocationWithDoorsData(updateLocation: UpdateLocation_updateLocation): UpdateLocation {
  return {
    updateLocation
  };
}

export const mockedUpdateLocationWithDoorsDataForMutation = mockedUpdateLocationWithDoorsData(
  createLocationWithDoors({ id: 'mockedLocationId0', name: 'mockedUpdatedLocationName' })
);
