import { gql } from '@apollo/client';

export const ON_OFFICE_DAY_ADDED = gql`
  subscription OnOfficeDayAdded($email: AWSEmail!) {
    onOfficeDayAdded(email: $email) {
      date
    }
  }
`;

export const ON_OFFICE_DAY_DELETED = gql`
  subscription OnOfficeDayDeleted($email: AWSEmail!) {
    onOfficeDayDeleted(email: $email) {
      date
    }
  }
`;

export const ON_MOBILE_DEVICE_REGISTERED = gql`
  subscription onMobileDeviceRegistered($email: AWSEmail!) {
    onMobileDeviceRegistered(email: $email) {
      email
      registrationDate
    }
  }
`;

export const ON_REGISTRATION_TOKEN_SAVED = gql`
  subscription onRegistrationTokenSaved($email: AWSEmail!) {
    onRegistrationTokenSaved(email: $email) {
      email
      registrationToken
    }
  }
`;

export const ON_OFFICE_DAY_ADDED_BY_ID = gql`
  subscription OnOfficeDayAddedById($visitorId: ID!) {
    onOfficeDayAddedById(visitorId: $visitorId) {
      date
    }
  }
`;

export const ON_OFFICE_DAY_DELETED_BY_ID = gql`
  subscription OnOfficeDayDeletedById($visitorId: ID!) {
    onOfficeDayDeletedById(visitorId: $visitorId) {
      date
    }
  }
`;

export const ON_MOBILE_DEVICE_REGISTERED_BY_ID = gql`
  subscription onMobileDeviceRegisteredById($visitorId: ID!) {
    onMobileDeviceRegisteredById(visitorId: $visitorId) {
      visitorId
      mobileDeviceId
      registrationDate
    }
  }
`;

export const ON_REGISTRATION_TOKEN_SAVED_BY_ID = gql`
  subscription onRegistrationTokenSavedById($visitorId: ID!) {
    onRegistrationTokenSavedById(visitorId: $visitorId) {
      visitorId
      mobileDeviceId
      registrationToken
    }
  }
`;
