import { AddCoContractorGroupForm } from 'components/Drawer/types';
import useCoContractors from 'hooks/useCoContractors/useCoContractors';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AddCoContractorGroup from 'ui/organisms/Drawer/variants/AddCoContractorGroup';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';

const AddCoContractorVariant: React.FC = () => {
  const [nameInputValue, setNameInputValue] = useState('');
  const [isNameUnique, setIsNameUnique] = useState(true);
  const { register, handleSubmit, setValue } = useForm<AddCoContractorGroupForm>();
  const { hideDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { addVisitorGroup, addVisitorGroupMutationLoading, coContractorsGroups } = useCoContractors({
    handleFetchError
  });

  const handleNameInputOnChange = (value: string) => {
    setNameInputValue(value);
    if (coContractorsGroups.some((group) => group.name === value)) setIsNameUnique(false);
    else setIsNameUnique(true);
  };

  const onSubmit = async () => {
    if (!addVisitorGroupMutationLoading) {
      await addVisitorGroup(nameInputValue);
      hideDrawer();
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
  }, [nameInputValue, setValue]);
  return (
    <DrawerTemplate
      title="Add group"
      confirmButtonText="Add"
      id="drawer-add-external-group"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={!isNameUnique}
    >
      <AddCoContractorGroup
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={handleNameInputOnChange}
        isNameUnique={isNameUnique}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default AddCoContractorVariant;
