/* eslint-disable import/prefer-default-export */

import { DeleteVisitorById } from 'graphql/generated/DeleteVisitorById';

export function mockedDeleteVisitorByIdData(deleteVisitorById: string | null): DeleteVisitorById {
  return {
    deleteVisitorById
  };
}

export const mockedDeleteVisitorByIdDataForMutation = mockedDeleteVisitorByIdData('mockedVisitorIdCoContractor1');
