/* eslint-disable import/prefer-default-export */

import {
  UnassignToAccessGrants,
  UnassignToAccessGrants_unassignFromAccessGrants
} from 'graphql/generated/UnassignToAccessGrants';
import { createMockedAccessGrantWithLocations } from 'mocks/objectsBaseOnSchemaType/accessGrantWithLocations';

export function mockedUnassignToAccessGrantsData(
  unassignFromAccessGrants: UnassignToAccessGrants_unassignFromAccessGrants[]
): UnassignToAccessGrants {
  return {
    unassignFromAccessGrants
  };
}

export const mockedUnassignToAccessGrantsDataForMutation = mockedUnassignToAccessGrantsData([
  createMockedAccessGrantWithLocations({ id: 'mockedAccessGrantId0' })
]);

export const mockedUnassignToAccessGrantsDataForMutation1 = mockedUnassignToAccessGrantsData([
  createMockedAccessGrantWithLocations({ id: 'mockedAccessGrantId0' })
]);

export const mockedUnassignToAccessGrantsDataForMutation2 = mockedUnassignToAccessGrantsData([
  createMockedAccessGrantWithLocations({ id: 'mockedAccessGrantId0' }),
  createMockedAccessGrantWithLocations({ id: 'mockedAccessGrantId1' }),
  createMockedAccessGrantWithLocations({ id: 'mockedAccessGrantId2' })
]);
