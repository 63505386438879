/* eslint-disable import/prefer-default-export */

import {
  AssignAccessGrantsToDoors,
  AssignAccessGrantsToDoors_assignAccessGrantsToDoors
} from 'graphql/generated/AssignAccessGrantsToDoors';
import { createMockedAccessGrant } from 'mocks/objectsBaseOnSchemaType/accessGrant';
import { createMockedDoor } from 'mocks/objectsBaseOnSchemaType/door';

export function mockedAssignAccessGrantsToDoors(
  assignAccessGrantsToDoors: AssignAccessGrantsToDoors_assignAccessGrantsToDoors[]
): AssignAccessGrantsToDoors {
  return {
    assignAccessGrantsToDoors
  };
}

export const mockedAssignAccessGrantsToDoorsDataForMutation = mockedAssignAccessGrantsToDoors([
  createMockedAccessGrant({ id: 'mockedAccessGrantId', doors: [createMockedDoor({ id: 'mockedDoorId' })] })
]);
