/* eslint-disable import/prefer-default-export */
import {
  GetAccessEventsForTenant,
  GetAccessEventsForTenant_getAccessEventsForTenant
} from 'graphql/generated/GetAccessEventsForTenant';
import { createMockedAccessEvents } from 'mocks/objectsBaseOnSchemaType/accessEvent';
import { createMockedAccessEventsPage } from 'mocks/objectsBaseOnSchemaType/accessEventsPage';
import { createMockedAccessEventDoor } from 'mocks/objectsBaseOnSchemaType/doorAccessEvent';
import { createMockedAccessEventVisitor } from 'mocks/objectsBaseOnSchemaType/visitorAccessEvent';

export function mockedGetAccessEventsForTenantData(
  getAccessEventsForTenant: GetAccessEventsForTenant_getAccessEventsForTenant
): GetAccessEventsForTenant {
  return {
    getAccessEventsForTenant
  };
}

export const mockedGetAccessEventsForTenantDataForQueryFirstFetch = mockedGetAccessEventsForTenantData(
  createMockedAccessEventsPage({
    items: [
      createMockedAccessEvents({
        id: 'mockedAccessEventsId',
        visitor: createMockedAccessEventVisitor({ id: 'mockedVisitorId' }),
        door: createMockedAccessEventDoor({ id: 'mockedDoorId' })
      })
    ],
    nextToken: 'mockedNextToken'
  })
);

export const mockedGetAccessEventsForTenantDataForQuerySecondFetch = mockedGetAccessEventsForTenantData(
  createMockedAccessEventsPage({
    items: [
      createMockedAccessEvents({
        id: 'mockedAccessEventsId',
        visitor: createMockedAccessEventVisitor({ id: 'mockedVisitorId' }),
        door: createMockedAccessEventDoor({ id: 'mockedDoorId' })
      })
    ]
  })
);

export const mockedGetAccessEventsForTenantDataForQueryWithFiltersFirstFetch = mockedGetAccessEventsForTenantData(
  createMockedAccessEventsPage({
    items: [
      createMockedAccessEvents({
        id: 'mockedAccessEventsIdWithFilters',
        visitor: createMockedAccessEventVisitor({ id: 'mockedVisitorIdWithFilters' }),
        door: createMockedAccessEventDoor({ id: 'mockedDoorIdWithFilters' })
      })
    ],
    nextToken: 'mockedNextTokenWithFilters'
  })
);

export const mockedGetAccessEventsForTenantDataForQueryWithFiltersSecondFetch = mockedGetAccessEventsForTenantData(
  createMockedAccessEventsPage({
    items: [
      createMockedAccessEvents({
        id: 'mockedAccessEventsIdWithFilters',
        visitor: createMockedAccessEventVisitor({ id: 'mockedVisitorIdWithFilters' }),
        door: createMockedAccessEventDoor({ id: 'mockedDoorIdWithFilters' })
      })
    ]
  })
);
