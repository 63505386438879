/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenant,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenant';
import { createMockedAccessGrant } from 'mocks/objectsBaseOnSchemaType/accessGrant';
import { createMockedDoor } from 'mocks/objectsBaseOnSchemaType/door';
import { createMockedVisitorGroupWithType } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithType';

export function getVisitorGroupsForTenantWithTypesDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenant {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithTypesDataForQuery = getVisitorGroupsForTenantWithTypesDataForQuery([
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId0',
    defaultAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId0', doors: [createMockedDoor({ id: 'mockedDoorId0' })] })
    ]
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId1',
    scheduledAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId1', doors: [createMockedDoor({ id: 'mockedDoorId1' })] })
    ]
  })
]);
