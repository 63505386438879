import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE
} from 'graphql/queries';
import { useEffect } from 'react';
import { coContractorsGroupsVar } from 'state/vars';
import {
  addCoContractorsGroupsAction,
  addCoContractorsMemberAction,
  deleteVisitorGroupByIdAction,
  deleteVisitorByIdAction,
  setCoContractorsGroupsAction,
  setCoContractorsMemberAction,
  updateCoContractorsGroupsAction
} from 'state/actions/coContractorsGroups';
import { SetVisitor, SetVisitorVariables } from 'graphql/generated/SetVisitor';
import {
  ADD_VISITOR,
  ADD_VISITOR_GROUP,
  ADD_VISITOR_WITH_ROLE,
  DELETE_VISITOR_BY_ID,
  DELETE_VISITOR_GROUP_BY_ID,
  SEND_INVITATION_FOR_MOBILE_DEVICE,
  SEND_INVITATION_FOR_MOBILE_DEVICE_BY_ID,
  SET_VISITOR,
  SET_VISITOR_BY_ID,
  SET_VISITOR_BY_ID_WITH_ROLE,
  SET_VISITOR_WITH_ROLE,
  UPDATE_VISITOR_GROUP
} from 'graphql/mutations';
import { AddVisitorGroup, AddVisitorGroupVariables } from 'graphql/generated/AddVisitorGroup';
import { UpdateVisitorGroup, UpdateVisitorGroupVariables } from 'graphql/generated/UpdateVisitorGroup';
import { AddVisitor, AddVisitorVariables } from 'graphql/generated/AddVisitor';
import {
  GetVisitorGroupsForTenantWithMembers,
  GetVisitorGroupsForTenantWithMembersVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { DeleteVisitorGroupById, DeleteVisitorGroupByIdVariables } from 'graphql/generated/DeleteVisitorGroupById';
import { DeleteVisitorById, DeleteVisitorByIdVariables } from 'graphql/generated/DeleteVisitorById';
import {
  SendInvitationForMobileDevice,
  SendInvitationForMobileDeviceVariables
} from 'graphql/generated/SendInvitationForMobileDevice';
import dayjs from 'dayjs';
import {
  GetVisitorGroupsForTenantWithMembersAndRole,
  GetVisitorGroupsForTenantWithMembersAndRoleVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { SetVisitorWithRole, SetVisitorWithRoleVariables } from 'graphql/generated/SetVisitorWithRole';
import { VisitorGroupMembershipInput } from 'graphql/generated/globalTypes';
import { AddVisitorWithRole, AddVisitorWithRoleVariables } from 'graphql/generated/AddVisitorWithRole';
import {
  SendInvitationForMobileDeviceById,
  SendInvitationForMobileDeviceByIdVariables
} from 'graphql/generated/SendInvitationForMobileDeviceById';
import { SetVisitorById, SetVisitorByIdVariables } from 'graphql/generated/SetVisitorById';
import { SetVisitorByIdWithRole, SetVisitorByIdWithRoleVariables } from 'graphql/generated/SetVisitorByIdWithRole';
import { BaseHookProps } from '../shared/types';

const useCoContractors = ({ handleFetchError }: BaseHookProps) => {
  const isRetailEnable = process.env.REACT_APP_FEATURE_RETAIL === 'true';

  const coContractorsGroups = useReactiveVar(coContractorsGroupsVar);

  const [getCoContractorsMembers, { loading }] = useLazyQuery<
    GetVisitorGroupsForTenantWithMembers,
    GetVisitorGroupsForTenantWithMembersVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS, {
    onCompleted: (data) => {
      setCoContractorsGroupsAction(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching external groups');
    }
  });

  const [getCoContractorsMembersWithRole, { loading: loadingWithRole }] = useLazyQuery<
    GetVisitorGroupsForTenantWithMembersAndRole,
    GetVisitorGroupsForTenantWithMembersAndRoleVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE, {
    onCompleted: (data) => {
      setCoContractorsGroupsAction(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching external groups');
    }
  });

  useEffect(() => {
    if (isRetailEnable)
      getCoContractorsMembersWithRole({
        variables: {
          filter: { type: 'CO_CONTRACTORS' }
        }
      });
    else
      getCoContractorsMembers({
        variables: {
          filter: { type: 'CO_CONTRACTORS' }
        }
      });
  }, [getCoContractorsMembers, getCoContractorsMembersWithRole, isRetailEnable]);

  const [addVisitorGroupMutation, { loading: addVisitorGroupMutationLoading }] = useMutation<
    AddVisitorGroup,
    AddVisitorGroupVariables
  >(ADD_VISITOR_GROUP, {
    onCompleted: (data) => {
      addCoContractorsGroupsAction(data.addVisitorGroup);
    },
    onError: () => {
      handleFetchError('Error while adding external group');
    }
  });

  const [updateVisitorGroupMutation, { loading: updateVisitorGroupMutationLoading }] = useMutation<
    UpdateVisitorGroup,
    UpdateVisitorGroupVariables
  >(UPDATE_VISITOR_GROUP, {
    onCompleted: (data) => {
      updateCoContractorsGroupsAction(data.updateVisitorGroup);
    },
    onError: () => {
      handleFetchError('Error while updating external group');
    }
  });

  const [setVisitorMutation, { error: setVisitorMutationError, loading: updateVisitorMemberLoading }] = useMutation<
    SetVisitor,
    SetVisitorVariables
  >(SET_VISITOR, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message === 'Visitor is not only CO_CONTRACTOR')
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while updating external group member');
    }
  });

  const [
    setVisitorWithRoleMutation,
    { error: setVisitorWithRoleMutationError, loading: updateVisitorMemberWithRoleLoading }
  ] = useMutation<SetVisitorWithRole, SetVisitorWithRoleVariables>(SET_VISITOR_WITH_ROLE, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message.includes('Visitor is not only CO_CONTRACTOR'))
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while updating external group member');
    }
  });

  const [setVisitorByIdMutation, { error: setVisitorByIdMutationError, loading: updateVisitorMemberByIdLoading }] =
    useMutation<SetVisitorById, SetVisitorByIdVariables>(SET_VISITOR_BY_ID, {
      onCompleted: (data) => data,
      onError: (error) => {
        if (error.message.includes('Visitor is not only CO_CONTRACTOR'))
          handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
        else handleFetchError('Error while updating external group member');
      }
    });

  const [
    setVisitorByIdWithRoleMutation,
    { error: setVisitorByIdWithRoleMutationError, loading: updateVisitorMemberByIdWithRoleLoading }
  ] = useMutation<SetVisitorByIdWithRole, SetVisitorByIdWithRoleVariables>(SET_VISITOR_BY_ID_WITH_ROLE, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message.includes('Visitor is not only CO_CONTRACTOR'))
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while updating external group member');
    }
  });

  const [addVisitorMemberMutation, { loading: addVisitorMemberMutationLoading }] = useMutation<
    AddVisitor,
    AddVisitorVariables
  >(ADD_VISITOR, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message === 'Visitor already exists') handleFetchError('Visitor with given email already exists');
      if (error.message === 'Visitor is not only CO_CONTRACTOR')
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while adding external group member');
    }
  });

  const [addVisitorMemberWithRoleMutation, { loading: addVisitorMemberMutationWithRoleLoading }] = useMutation<
    AddVisitorWithRole,
    AddVisitorWithRoleVariables
  >(ADD_VISITOR_WITH_ROLE, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message === 'Visitor already exists') handleFetchError('Visitor with given email already exists');
      if (error.message === 'Visitor is not only CO_CONTRACTOR')
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while adding external group member');
    }
  });

  const [deleteVisitorByIdMutation, { loading: deleteVisitorByIdMutationLoading }] = useMutation<
    DeleteVisitorById,
    DeleteVisitorByIdVariables
  >(DELETE_VISITOR_BY_ID, {
    onCompleted: (data) => {
      deleteVisitorByIdAction(data.deleteVisitorById);
    },
    onError: () => {
      handleFetchError('Error while deleting Visitor');
    }
  });
  const [
    deleteVisitorGroupByIdMutation,
    { error: deleteVisitorGroupByIdMutationError, loading: deleteVisitorGroupByIdMutationLoading }
  ] = useMutation<DeleteVisitorGroupById, DeleteVisitorGroupByIdVariables>(DELETE_VISITOR_GROUP_BY_ID, {
    onCompleted: (data) => {
      deleteVisitorGroupByIdAction(data.deleteVisitorGroupById);
    }
  });

  const addVisitorGroup = async (name: string) => {
    const { data } = await addVisitorGroupMutation({
      variables: { name, type: 'CO_CONTRACTORS' },
      update(cache, { data: addedGroup }) {
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: [...coContractorsGroups, addedGroup?.addVisitorGroup]
          }
        });
      }
    });
    return data;
  };

  const [sendInvitationForMobileDeviceMutation, { loading: sendInvitationForMobileDeviceMutationLoading }] =
    useMutation<SendInvitationForMobileDevice, SendInvitationForMobileDeviceVariables>(
      SEND_INVITATION_FOR_MOBILE_DEVICE,
      {
        onError: (error) => {
          if (
            error.message === 'Too many requests' ||
            error.message === 'MobileDevice does not have registration token'
          )
            handleFetchError('Unable to send invite. Please wait 10 minutes before trying again');
          else handleFetchError('Error while resending invitation');
        }
      }
    );

  const [sendInvitationForMobileDeviceByIdMutation, { loading: sendInvitationForMobileDeviceByIdMutationLoading }] =
    useMutation<SendInvitationForMobileDeviceById, SendInvitationForMobileDeviceByIdVariables>(
      SEND_INVITATION_FOR_MOBILE_DEVICE_BY_ID,
      {
        onError: (error) => {
          if (
            error.message === 'Too many requests' ||
            error.message === 'MobileDevice does not have registration token'
          )
            handleFetchError('Unable to send invite. Please wait 10 minutes before trying again');
          else handleFetchError('Error while resending invitation');
        }
      }
    );

  const updateVisitorGroup = async (name: string, visitorGroupId: string) => {
    const { data } = await updateVisitorGroupMutation({
      variables: { name, visitorGroupId },
      update(cache, { data: updatedGroup }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.id === updatedGroup?.updateVisitorGroup.id) {
            return updatedGroup?.updateVisitorGroup;
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const addVisitorMember = async (
    name: string,
    email: string,
    visitorGroupExternalRefs: string[],
    expirationDate?: string
  ) => {
    const { data } = await addVisitorMemberMutation({
      variables: {
        name,
        email,
        visitorGroupExternalRefs,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined
      },
      update(cache, { data: addedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupExternalRefs[0]) {
            return { ...group, members: { ...group.members, addedMember } };
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      addCoContractorsMemberAction(data.addVisitor, visitorGroupExternalRefs[0]);
    }
    return data;
  };

  const addVisitorMemberWithRole = async (
    name: string,
    email: string,
    visitorGroupExternalRefs: string[],
    visitorGroupMembership: VisitorGroupMembershipInput[],
    expirationDate?: string
  ) => {
    const { data } = await addVisitorMemberWithRoleMutation({
      variables: {
        name,
        email,
        visitorGroupMembership,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined
      },
      update(cache, { data: addedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupExternalRefs[0] && addedMember) {
            return { ...group, members: [...group.members, addedMember.addVisitor] };
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      addCoContractorsMemberAction(data.addVisitor, visitorGroupExternalRefs[0]);
    }
    return data;
  };

  const updateVisitorMemberWithRole = async (
    email: string,
    name: string,
    visitorGroupMembership: VisitorGroupMembershipInput[],
    expirationDate?: string
  ) => {
    const { data } = await setVisitorWithRoleMutation({
      variables: {
        email,
        name,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined,
        visitorGroupMembership
      },

      update(cache, { data: updatedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupMembership[0].visitorGroupExternalRef)
            if (group.members.some((member) => member.email === updatedMember?.setVisitor.email))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.email === updatedMember?.setVisitor.email ? updatedMember?.setVisitor : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, ...updatedMember?.setVisitor }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.email !== updatedMember?.setVisitor.email)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setCoContractorsMemberAction(
        data.setVisitor,
        visitorGroupMembership.map((membership) => membership.visitorGroupExternalRef)
      );
    }
    return data;
  };

  const updateVisitorMember = async (
    email: string,
    name: string,
    visitorGroupExternalRefs: string[],
    expirationDate?: string
  ) => {
    const { data } = await setVisitorMutation({
      variables: {
        email,
        name,
        visitorGroupExternalRefs,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined
      },
      update(cache, { data: updatedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupExternalRefs[0])
            if (group.members.some((member) => member.email === updatedMember?.setVisitor.email))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.email === updatedMember?.setVisitor.email ? updatedMember : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, updatedMember }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.email !== updatedMember?.setVisitor.email)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setCoContractorsMemberAction(data.setVisitor, visitorGroupExternalRefs);
    }
    return data;
  };

  const updateVisitorMemberByIdWithRole = async (
    visitorId: string,
    name: string,
    visitorGroupMembership: VisitorGroupMembershipInput[],
    expirationDate?: string
  ) => {
    const { data } = await setVisitorByIdWithRoleMutation({
      variables: {
        visitorId,
        name,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined,
        visitorGroupMembership
      },

      update(cache, { data: updatedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupMembership[0].visitorGroupExternalRef)
            if (group.members.some((member) => member.id === updatedMember?.setVisitorById.id))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.id === updatedMember?.setVisitorById.id ? updatedMember?.setVisitorById : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, ...updatedMember?.setVisitorById }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.id !== updatedMember?.setVisitorById.id)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setCoContractorsMemberAction(
        data.setVisitorById,
        visitorGroupMembership.map((membership) => membership.visitorGroupExternalRef)
      );
    }
    return data;
  };

  const updateVisitorMemberById = async (
    visitorId: string,
    name: string,
    visitorGroupExternalRefs: string[],
    expirationDate?: string
  ) => {
    const { data } = await setVisitorByIdMutation({
      variables: {
        visitorId,
        name,
        visitorGroupExternalRefs,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined
      },
      update(cache, { data: updatedMember }) {
        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.externalRef === visitorGroupExternalRefs[0])
            if (group.members.some((member) => member.id === updatedMember?.setVisitorById.id))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.id === updatedMember?.setVisitorById.id ? updatedMember : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, updatedMember }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.id !== updatedMember?.setVisitorById.id)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setCoContractorsMemberAction(data.setVisitorById, visitorGroupExternalRefs);
    }
    return data;
  };

  const deleteVisitorById = async (visitorId: string) => {
    const { data } = await deleteVisitorByIdMutation({
      variables: { visitorId },
      update(cache, { data: deletedMemberData }) {
        const groupWithDeletedMember = coContractorsGroups.filter((group) =>
          group.members.some((member) => member.id === visitorId)
        )[0];

        const updatedGroups = coContractorsGroups.map((group) => {
          if (group.id === groupWithDeletedMember.id)
            return {
              ...group,
              members: group.members.filter((member) => member.id !== deletedMemberData?.deleteVisitorById)
            };
          return group;
        });

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const deleteVisitorGroupById = async (visitorGroupId: string) => {
    const { data } = await deleteVisitorGroupByIdMutation({
      variables: { visitorGroupId, withVisitors: true },
      update(cache, { data: removedVisitorGroupById }) {
        const updatedGroups = coContractorsGroups.filter(
          (group) => group.id !== removedVisitorGroupById?.deleteVisitorGroupById
        );

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const sendInvitationForMobileDevice = async (email: string, mobileDeviceId: string) => {
    const { data } = await sendInvitationForMobileDeviceMutation({
      variables: { email, mobileDeviceId }
    });
    return data;
  };

  const sendInvitationForMobileDeviceById = async (visitorId: string, mobileDeviceId: string) => {
    const { data } = await sendInvitationForMobileDeviceByIdMutation({
      variables: { visitorId, mobileDeviceId }
    });
    return data;
  };

  return {
    coContractorsGroups,
    addVisitorGroup,
    addVisitorGroupMutationLoading,
    updateVisitorGroup,
    updateVisitorGroupMutationLoading,
    updateVisitorMember,
    updateVisitorMemberLoading,
    updateVisitorMemberWithRole,
    updateVisitorMemberWithRoleLoading,
    updateVisitorMemberById,
    updateVisitorMemberByIdLoading,
    updateVisitorMemberByIdWithRole,
    updateVisitorMemberByIdWithRoleLoading,
    addVisitorMember,
    addVisitorMemberMutationLoading,
    addVisitorMemberWithRole,
    addVisitorMemberMutationWithRoleLoading,
    deleteVisitorById,
    deleteVisitorByIdMutationLoading,
    deleteVisitorGroupByIdMutationError,
    deleteVisitorGroupByIdMutationLoading,
    deleteVisitorGroupById,
    setVisitorMutationError,
    setVisitorWithRoleMutationError,
    setVisitorByIdMutationError,
    setVisitorByIdWithRoleMutationError,
    sendInvitationForMobileDevice,
    sendInvitationForMobileDeviceMutationLoading,
    sendInvitationForMobileDeviceById,
    sendInvitationForMobileDeviceByIdMutationLoading,
    loading,
    loadingWithRole
  };
};

export default useCoContractors;
