import { EditCoContractorGroupForm } from 'components/Drawer/types';
import useCoContractors from 'hooks/useCoContractors/useCoContractors';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useModal from 'hooks/useModal/useModal';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditCoContractorGroupDrawer } from 'state/types';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import EditCoContractorGroup from 'ui/organisms/Drawer/variants/EditCoContractorGroup';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';

const EditCoContractorGroupVariant: React.FC = () => {
  const { drawerState } = useDrawer<EditCoContractorGroupDrawer>();
  const [nameInputValue, setNameInputValue] = useState(drawerState.contentValue.name);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const { register, handleSubmit, setValue } = useForm<EditCoContractorGroupForm>();
  const { hideDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { coContractorsGroups, updateVisitorGroup, updateVisitorGroupMutationLoading } = useCoContractors({
    handleFetchError
  });
  const { showModal } = useModal();

  const handleNameInputOnChange = (value: string) => {
    setNameInputValue(value);
    if (coContractorsGroups.some((group) => group.name === value) && value !== drawerState.contentValue.name)
      setIsNameUnique(false);
    else setIsNameUnique(true);
  };

  const handleDeleteCoContractorGroup = () => {
    if (drawerState.contentValue.visitorGroupId)
      showModal({
        type: 'deleteCoContractorGroupNew',
        contentValue: { visitorGroupId: drawerState.contentValue.visitorGroupId }
      });
  };

  const onSubmit = async () => {
    if (!updateVisitorGroupMutationLoading) {
      await updateVisitorGroup(nameInputValue, drawerState.contentValue.visitorGroupId);
      hideDrawer();
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
  }, [nameInputValue, setValue]);

  return (
    <DrawerTemplate
      title="Edit group"
      confirmButtonText="Save"
      id="drawer-edit-external-group"
      headerSideText={
        <ComponentWrapper id="drawer-delete-external-group">
          <Typography variant="clickable" color="dTextHigh">
            DELETE
          </Typography>
          <Icon name="DeleteBin" color="dTextHigh" height="16" width="16" data-testid="svg-delete" />
        </ComponentWrapper>
      }
      headerSideTextOnClick={handleDeleteCoContractorGroup}
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={!isNameUnique}
    >
      <EditCoContractorGroup
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={handleNameInputOnChange}
        isNameUnique={isNameUnique}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default EditCoContractorGroupVariant;
