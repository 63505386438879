/* eslint-disable import/prefer-default-export */

import { OnOfficeDayAdded_onOfficeDayAdded } from 'graphql/generated/OnOfficeDayAdded';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedSubscriptionOfficeDayAdded({ date }: Props): OnOfficeDayAdded_onOfficeDayAdded {
  return {
    __typename: 'SubscriptionOfficeDay',
    date
  };
}
