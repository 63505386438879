import useModal from 'hooks/useModal/useModal';
import React from 'react';
import UIModal from 'ui/atoms/Modal/Modal';
import DataTimePicker from './variants/DataTimePicker';
import DisconnectMobileDevice from './variants/DisconnectMobileDevice';
import RemoveOrganizationFromMobileApp from './variants/RemoveOrganizationFromMobileApp';
import EditSchedule from './variants/EditSchedule';
import DeleteCoContractorGroup from './variants/DeleteCoContractorGroup';
import ChangeGroupCoContractor from './variants/ChangeGroupCoContractor';
import DeleteCoContractor from './variants/DeleteCoContractor';
import DeleteInstaller from './variants/DeleteInstaller';
import ResendInvitation from './variants/ResendInvitation';
import ExpirationDate from './variants/ExpirationDate';
import DeleteLockGroup from './variants/DeleteLockGroup';
import ManageLockGroup from './variants/LockGroup/ManageLockGroup';
import CreateExportAccessEvents from './variants/CreateExportAccessEvents';
import PreparingExportAccessEvents from './variants/PreparingExportAccessEvents';

const Modal: React.FC = () => {
  const { modalState } = useModal();

  const renderCorrectVariant = () => {
    switch (modalState?.type) {
      case 'dataTimePicker':
        return <DataTimePicker />;
      case 'disconnectMobileDevice':
        return <DisconnectMobileDevice />;
      case 'removeOrganizationFromMobileApp':
        return <RemoveOrganizationFromMobileApp />;
      case 'deleteCoContractor':
        return <DeleteCoContractor />;
      case 'editSchedule':
        return <EditSchedule />;
      case 'deleteCoContractorGroupNew':
        return <DeleteCoContractorGroup />;
      case 'changeGroupCoContractor':
        return <ChangeGroupCoContractor />;
      case 'deleteInstaller':
        return <DeleteInstaller />;
      case 'resendInvitation':
        return <ResendInvitation />;
      case 'expirationDate':
        return <ExpirationDate />;
      case 'manageLockGroup':
        return <ManageLockGroup />;
      case 'deleteLockGroup':
        return <DeleteLockGroup />;
      case 'createExportAccessEvents':
        return <CreateExportAccessEvents />;
      case 'preparingExportAccessEvents':
        return <PreparingExportAccessEvents />;
      default:
        return null;
    }
  };
  return modalState?.type ? <UIModal>{renderCorrectVariant()}</UIModal> : <></>;
};

export default Modal;
