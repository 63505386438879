/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithMembersAndRole,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { Role } from 'graphql/generated/globalTypes';
import { createMockedMobileDevice } from 'mocks/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitorGroupMembership } from 'mocks/objectsBaseOnSchemaType/visitorGroupMembership';
import { createMockedVisitorGroupWithMembersAndRoleCoContractors } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function getVisitorGroupsForTenantWithMembersAndRoleDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithMembersAndRole {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithMembersAndRoleDataForQuery =
  getVisitorGroupsForTenantWithMembersAndRoleDataForQuery([
    createMockedVisitorGroupWithMembersAndRoleCoContractors({
      id: 'mockedVisitorGroupIdCoContractors1',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCoContractor1',
          name: 'mockedVisitorNameCoContractor1',
          email: 'mockedVisitorEmailCoContractor1',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ]
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCoContractor2',
          name: 'mockedVisitorNameCoContractor2',
          email: 'mockedVisitorEmailCoContractor2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({ visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs' })
          ]
        })
      ],
      name: 'co-contractors1',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs'
    })
  ]);
