/* eslint-disable import/prefer-default-export */

import { SetInstaller, SetInstaller_setInstaller } from 'graphql/generated/SetInstaller';
import { createMockedVisitor } from 'mocks/objectsBaseOnSchemaType/visitor';

export function mockedSetInstallerData(setInstaller: SetInstaller_setInstaller): SetInstaller {
  return {
    setInstaller
  };
}

export const mockedSetInstallerDataForMutation = mockedSetInstallerData(
  createMockedVisitor({ id: 'mockedInstallerIdNew', name: 'mockedInstallerNameNew', email: 'mockedInstallerEmailNew' })
);
