/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import { OnOfficeDayDeleted, OnOfficeDayDeleted_onOfficeDayDeleted } from 'graphql/generated/OnOfficeDayDeleted';
import { createMockedSubscriptionOfficeDayDeleted } from 'mocks/objectsBaseOnSchemaType/subscriptionOfficeDayDeleted';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { firstDate } from '../dateUsedInMocks';

export function mockedOnOfficeDayDeletedData(
  onOfficeDayDeleted: OnOfficeDayDeleted_onOfficeDayDeleted | null
): OnOfficeDayDeleted {
  return {
    onOfficeDayDeleted
  };
}

export const mockedOnOfficeDayDeletedDataForSubscription = mockedOnOfficeDayDeletedData(
  createMockedSubscriptionOfficeDayDeleted({
    date: getDateWithFormat(
      'AWSDate',
      dayjs(firstDate).year(),
      dayjs(firstDate).month(),
      dayjs(firstDate).startOf('month').date()
    )
  })
);
