import React from 'react';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableRow from 'ui/molecules/TableRow/TableRow';
import Table from 'ui/organisms/Table/Table';
import SortingMenu from 'ui/organisms/SortingMenu/SortingMenu';
import getVisitorGroupIcon, { VisitorGroupType } from 'utils/VisitorGroupIcon/VisitorGroupIcon.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { VisitorsGroupsWithAccessGrantsState } from 'state/types';
import ArrayUtil from 'utils/Array/Array.util';
import { GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { Props as IconProps } from 'ui/atoms/Icon/Icon';
import useSortingAccessProfilesTableVisitorGroup, {
  userGroupsPrioritySorting
} from 'hooks/useSorting/useSortingAccessProfiles/VisitorGroups/useSortingAccessProfilesTableVisitorGroup';
import {
  ARROW_DOWN_HEIGHT,
  ARROW_DOWN_WIDTH,
  ARROW_RIGHT_HEIGHT,
  ARROW_RIGHT_WIDTH,
  SecondLineText,
  Variant
} from '../shared';

export interface Props {
  id: string;
  loading: boolean;
  disabled: boolean;
  isCollapsed: boolean;
  handleCollapse: () => void;
  variant: Variant;
  filteredVisitorGroups: VisitorsGroupsWithAccessGrantsState;
  visitorGroups: VisitorsGroupsWithAccessGrantsState;
  selectedIds: string[];
  disabledIds: string[];
  additionalTexts: SecondLineText[];
  warningTexts: SecondLineText[];
  handleClickTableRow: (group: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) => void;
}

const AccessProfilesVisitorGroups: React.FC<Props> = ({
  id,
  loading,
  disabled,
  isCollapsed,
  handleCollapse,
  variant,
  filteredVisitorGroups,
  visitorGroups,
  selectedIds,
  disabledIds,
  additionalTexts,
  warningTexts,
  handleClickTableRow
}) => {
  const { sorting, handleOpenSortingMenu, handleSortingOnChange, sortingMenuRef } =
    useSortingAccessProfilesTableVisitorGroup({
      visitorGroups,
      selectedIds
    });
  const skeletonArray = ArrayUtil.SkeletonArray();

  const getIsAccessByReservationEnabled = (
    visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
  ) => Boolean(visitorGroup.scheduledAccessGrants.length < visitorGroup.defaultAccessGrants.length);

  const isVisitorGroupSelected = (visitorGroupId: string) => selectedIds.includes(visitorGroupId);
  const isVisitorGroupDisabled = (visitorGroupId: string) => disabledIds.includes(visitorGroupId);

  const getVisitorGroupWithAdditionalText = (visitorGroupId: string) =>
    additionalTexts.find((item) => item.id === visitorGroupId)?.text;

  const getVisitorGroupWithWarningText = (visitorGroupId: string) =>
    warningTexts.find((item) => item.id === visitorGroupId)?.text;

  const getSecondLineText = (visitorGroupId: string) =>
    getVisitorGroupWithAdditionalText(visitorGroupId) || getVisitorGroupWithWarningText(visitorGroupId);

  const getIconAndSecondLineTextColor = (visitorGroupId: string) =>
    getVisitorGroupWithWarningText(visitorGroupId) ? 'warning' : 'lTextHigh';

  const getFirstLineTextColor = (visitorGroupId: string) =>
    variant === 'icon' && isVisitorGroupSelected(visitorGroupId) && !isVisitorGroupDisabled(visitorGroupId)
      ? 'primary'
      : 'lTextHigh';

  const getAfterContentIconProps = (
    visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
  ): Pick<IconProps, 'name' | 'color'> | undefined => {
    if (variant === 'icon' && getIsAccessByReservationEnabled(visitorGroup))
      return {
        name: 'TodayOutlined',
        color: 'lTextHigh'
      };
    return undefined;
  };

  const getBeforeContentIconProps = (
    visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
  ): IconProps => ({
    name: getVisitorGroupIcon.getVisitorGroupIcon(visitorGroup.type as VisitorGroupType),
    width: 28,
    height: 28,
    viewBox: '0 0 32 32',
    color: 'transparent',
    stroke: getIconAndSecondLineTextColor(visitorGroup.id)
  });

  return (
    <Table
      id={id}
      header={
        !loading ? (
          <ComponentWrapper flexDirection="column">
            <SortingMenu
              // @ts-ignore
              ref={sortingMenuRef}
              isOpen={sorting.isMenuOpen}
              sortingName="Alphabetic Order"
              sortingOptions={[
                {
                  isSelected: !!sorting.isAlphabeticAscending,
                  onClick: () => handleSortingOnChange(sorting.priority, true),
                  text: 'Ascending',
                  id: 'sorting-option-ascending'
                },
                {
                  isSelected: !sorting.isAlphabeticAscending,
                  onClick: () => handleSortingOnChange(sorting.priority, false),
                  text: 'Descending',
                  id: 'sorting-option-descending'
                }
              ]}
              additionalSortingName="Priority"
              additionalSortingOptions={
                !loading && [
                  ...userGroupsPrioritySorting.map((priority) => ({
                    isSelected: sorting.priority === priority,
                    onClick: () => handleSortingOnChange(priority, sorting.isAlphabeticAscending),
                    text: priority,
                    id: `sorting-option-${priority}`
                  })),
                  variant === 'switch' && !disabled
                    ? {
                        isSelected: sorting.priority === 'Selected',
                        onClick: () => handleSortingOnChange('Selected', sorting.isAlphabeticAscending),
                        text: 'Selected',
                        id: 'sorting-option-selected'
                      }
                    : undefined
                ]
              }
            >
              <TableHeaderRow
                disabled={disabled}
                id="access-profiles-header-row-user-groups"
                iconAfterHeaderProps={{ name: 'Sorting', onClick: !disabled ? handleOpenSortingMenu : undefined }}
                iconAfterHeaderBorder
                iconBeforeHeaderProps={{
                  name: isCollapsed ? 'ArrowRight' : 'ArrowDown',
                  width: isCollapsed ? ARROW_RIGHT_WIDTH : ARROW_DOWN_WIDTH,
                  height: isCollapsed ? ARROW_RIGHT_HEIGHT : ARROW_DOWN_HEIGHT,
                  color: isCollapsed ? undefined : 'transparent',
                  stroke: isCollapsed ? undefined : 'lTextMedium',
                  viewBox: isCollapsed ? '0 0 22 45' : undefined,
                  onClick: handleCollapse
                }}
              >
                <TableHeader
                  headerText="User groups"
                  superText={variant === 'switch' ? `(${selectedIds.length})` : undefined}
                  headerTextOpacity={disabled ? '0.3' : '1'}
                  id="access-profiles-header-user-groups"
                />
              </TableHeaderRow>
            </SortingMenu>
          </ComponentWrapper>
        ) : (
          <ComponentWrapper flexDirection="column">
            <TableHeaderRow disabled={disabled} id="access-profiles-header-row-user-groups">
              <TableHeader
                headerText="User groups"
                iconSorting="ArrowDown"
                onClick={handleOpenSortingMenu}
                id="access-profiles-header-user-groups"
              />
            </TableHeaderRow>
          </ComponentWrapper>
        )
      }
    >
      {loading
        ? skeletonArray.map((skeletonItem) => (
            <TableRow
              beforeSwitchSkeleton={variant === 'switch'}
              avatarSkeleton={variant === 'icon'}
              selected={false}
              disabled
              id="access-profiles-row-user-groups-loading"
              key={`skeletonTableRow-${skeletonItem.id}`}
            >
              <TableCell isLoading firstLineText="" />
            </TableRow>
          ))
        : !isCollapsed &&
          filteredVisitorGroups.map((group, index) => (
            <TableRow
              hoverEffect
              afterSwitchProps={
                variant !== 'switch'
                  ? undefined
                  : {
                      checked: isVisitorGroupSelected(group.id),
                      disabled: isVisitorGroupDisabled(group.id),
                      onClick: (event) => event.stopPropagation()
                    }
              }
              marginAfterContent={variant === 'switch'}
              beforeContentIconProps={getBeforeContentIconProps(group)}
              selected={variant === 'icon' ? isVisitorGroupSelected(group.id) : false}
              disabled={(variant === 'switch' && disabled) || isVisitorGroupDisabled(group.id)}
              onClick={!disabled && !isVisitorGroupDisabled(group.id) ? () => handleClickTableRow(group) : undefined}
              afterContentIconProps={getAfterContentIconProps(group)}
              id={`access-profiles-row-user-groups-${index}`}
              key={group.id}
            >
              <TableCell
                firstLineText={group.name}
                firstLineColor={getFirstLineTextColor(group.id)}
                secondLineText={getSecondLineText(group.id)}
                secondLineColor={getIconAndSecondLineTextColor(group.id)}
                key={group.id}
              />
            </TableRow>
          ))}
    </Table>
  );
};

export default AccessProfilesVisitorGroups;
