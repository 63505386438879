import React from 'react';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  handleOnButtonClick?: () => void;
}

const NoCoContractorsWithButton: React.FC<Props> = ({ handleOnButtonClick }) => (
  <ComponentWrapper
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap="1.5rem"
    width="20.125rem"
    height="14rem"
  >
    <Icon name="UserCoContractors" width={56} height={56} viewBox="0 0 24 24" />
    <Typography variant="title4" id="no-external-group-member-label">
      No users
    </Typography>
    <ComponentWrapper flexDirection="column">
      <Typography variant="body4">There are no users assigned.</Typography>
      <Typography variant="body4">Click “Add” to add a new user</Typography>
    </ComponentWrapper>
    <ComponentWrapper width="11rem">
      <Button onClick={handleOnButtonClick} id="add-external-group-member-button">
        Add
      </Button>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default NoCoContractorsWithButton;
