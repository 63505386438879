import { EditCoContractorGroupForm } from 'components/Drawer/types';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Input from 'ui/molecules/Input/Input';

export interface Props {
  register: UseFormRegister<EditCoContractorGroupForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  isNameUnique: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const EditCoContractorGroup: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  isNameUnique,
  inputOnKeyDown
}) => (
  <>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      required
      label="Name*"
      value={nameInputValue}
      id="drawer-input-name"
      failed={!isNameUnique}
      failedText="Group name must be unique"
      failedId="drawer-input-name-validation"
      onKeyDown={inputOnKeyDown}
    />
  </>
);

export default EditCoContractorGroup;
