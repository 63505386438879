/* eslint-disable import/prefer-default-export */
import { ON_REGISTRATION_TOKEN_SAVED, ON_OFFICE_DAY_ADDED, ON_OFFICE_DAY_DELETED } from 'graphql/subscriptions';
import { mockedOnRegistrationTokenSavedDataForSubscription } from './subscription/onRegistrationTokenSaved';
import { mockedOnOfficeDayAddedDataForSubscription } from './subscription/onOfficeDayAdded';
import { mockedOnOfficeDayDeletedDataForSubscription } from './subscription/onOfficeDayDeleted';

export const subscriptions = [
  {
    request: {
      query: ON_OFFICE_DAY_ADDED,
      variables: {
        email: 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com'
      }
    },
    newData: () => ({
      data: mockedOnOfficeDayAddedDataForSubscription
    })
  },
  {
    request: {
      query: ON_OFFICE_DAY_DELETED,
      variables: {
        email: 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com'
      }
    },
    newData: () => ({
      data: mockedOnOfficeDayDeletedDataForSubscription
    })
  },
  {
    request: {
      query: ON_REGISTRATION_TOKEN_SAVED,
      variables: { email: 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com' }
    },
    newData: () => ({
      data: mockedOnRegistrationTokenSavedDataForSubscription
    })
  }
];
