/* eslint-disable import/prefer-default-export */

import {
  onRegistrationTokenSaved as onRegistrationTokenSavedType,
  onRegistrationTokenSaved_onRegistrationTokenSaved
} from 'graphql/generated/onRegistrationTokenSaved';
import { createMockedSubscriptionRegistrationTokenSaved } from 'mocks/objectsBaseOnSchemaType/subscriptionRegistrationTokenSaved';

export function mockedOnRegistrationTokenSavedData(
  onRegistrationTokenSaved: onRegistrationTokenSaved_onRegistrationTokenSaved | null
): onRegistrationTokenSavedType {
  return {
    onRegistrationTokenSaved
  };
}

export const mockedOnRegistrationTokenSavedDataForSubscription = mockedOnRegistrationTokenSavedData(
  createMockedSubscriptionRegistrationTokenSaved({})
);
