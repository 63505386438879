/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { createMockedAccessGrantWithLocations } from 'mocks/objectsBaseOnSchemaType/accessGrantWithLocations';
import { createMockedVisitorGroupWithAccessGrants } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithAccessGrants';
import { mockedGetLocationWithDoorsForTenantDataDataForQuery } from './getLocationWithDoorsForTenant';

export function mockedGetVisitorGroupsForTenantWithAccessGrantsData(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithAccessGrants {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithAccessGrantsDataDataForQuery =
  mockedGetVisitorGroupsForTenantWithAccessGrantsData([
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId0',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId0',
          locations: [mockedGetLocationWithDoorsForTenantDataDataForQuery.getLocationsForTenant[0]]
        })
      ],
      name: 'group 0'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId1',
      scheduledAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId1',
          locations: [mockedGetLocationWithDoorsForTenantDataDataForQuery.getLocationsForTenant[1]]
        })
      ],
      name: 'group 1'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId2',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId2',
          locations: [
            mockedGetLocationWithDoorsForTenantDataDataForQuery.getLocationsForTenant[1],
            mockedGetLocationWithDoorsForTenantDataDataForQuery.getLocationsForTenant[2]
          ]
        })
      ],
      name: 'group 2'
    })
  ]);
