import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS } from 'graphql/queries';
import { useEffect } from 'react';
import { installersGroupsVar } from 'state/vars';
import {
  setInstallersGroupsAction,
  setInstallersMemberAction,
  unsetInstallerAction,
  unsetInstallerByIdAction
} from 'state/actions/installersGroups';
import {
  SET_INSTALLER,
  SET_VISITOR,
  SET_VISITOR_BY_ID,
  UNSET_INSTALLER,
  UNSET_INSTALLER_BY_ID
} from 'graphql/mutations';
import {
  GetVisitorGroupsForTenantWithMembers,
  GetVisitorGroupsForTenantWithMembersVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { SetInstaller, SetInstallerVariables } from 'graphql/generated/SetInstaller';
import { UnsetInstaller, UnsetInstallerVariables } from 'graphql/generated/UnsetInstaller';
import { SetVisitor, SetVisitorVariables } from 'graphql/generated/SetVisitor';
import { UnsetInstallerById, UnsetInstallerByIdVariables } from 'graphql/generated/UnsetInstallerById';
import { SetVisitorById, SetVisitorByIdVariables } from 'graphql/generated/SetVisitorById';
import { BaseHookProps } from '../shared/types';

const useInstallers = ({ handleFetchError }: BaseHookProps) => {
  const installersGroups = useReactiveVar(installersGroupsVar);

  const [getInstallersMembers, { loading }] = useLazyQuery<
    GetVisitorGroupsForTenantWithMembers,
    GetVisitorGroupsForTenantWithMembersVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS, {
    onCompleted: (data) => {
      setInstallersGroupsAction(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching installers');
    }
  });

  useEffect(() => {
    getInstallersMembers({
      variables: {
        filter: { type: 'INSTALLERS' }
      }
    });
  }, [getInstallersMembers]);

  const installersGroupExternalRef = installersGroups.find((group) => group.type === 'INSTALLERS')?.externalRef;

  const [setInstallerMutation, { loading: setInstallerMemberLoading }] = useMutation<
    SetInstaller,
    SetInstallerVariables
  >(SET_INSTALLER, {
    onCompleted: (data) => data,
    onError: () => {
      handleFetchError('Error while setting installer');
    }
  });
  const setInstallerMember = async (email: string, name: string) => {
    const { data } = await setInstallerMutation({
      variables: { email, name },
      update(cache, { data: updatedMember }) {
        const updatedGroups = installersGroups.map((group) => {
          if (group.externalRef === installersGroupExternalRef)
            if (group.members.some((member) => member.email === updatedMember?.setInstaller.email))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.email === updatedMember?.setInstaller.email ? updatedMember : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, updatedMember }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.email !== updatedMember?.setInstaller.email)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'INSTALLERS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setInstallersMemberAction(data.setInstaller, [installersGroupExternalRef!]);
    }
    return data;
  };

  const [updateInstallerMutation, { loading: updateInstallerMemberLoading }] = useMutation<
    SetVisitor,
    SetVisitorVariables
  >(SET_VISITOR, {
    onCompleted: (data) => data,
    onError: () => handleFetchError('Error while updating installer')
  });
  const updateInstallerMember = async (email: string, name: string) => {
    const { data } = await updateInstallerMutation({
      variables: { email, name, visitorGroupExternalRefs: [installersGroupExternalRef!] },
      update(cache, { data: updatedMember }) {
        const updatedGroups = installersGroups.map((group) => {
          if (group.externalRef === installersGroupExternalRef)
            if (group.members.some((member) => member.email === updatedMember?.setVisitor.email))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.email === updatedMember?.setVisitor.email ? updatedMember : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, updatedMember }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.email !== updatedMember?.setVisitor.email)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'INSTALLERS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setInstallersMemberAction(data.setVisitor, [installersGroupExternalRef!]);
    }
    return data;
  };

  const [updateInstallerByIdMutation, { loading: updateInstallerByIdMemberLoading }] = useMutation<
    SetVisitorById,
    SetVisitorByIdVariables
  >(SET_VISITOR_BY_ID, {
    onCompleted: (data) => data,
    onError: () => handleFetchError('Error while updating installer')
  });
  const updateInstallerByIdMember = async (visitorId: string, name: string) => {
    const { data } = await updateInstallerByIdMutation({
      variables: { visitorId, name, visitorGroupExternalRefs: [installersGroupExternalRef!] },
      update(cache, { data: updatedMember }) {
        const updatedGroups = installersGroups.map((group) => {
          if (group.externalRef === installersGroupExternalRef)
            if (group.members.some((member) => member.id === updatedMember?.setVisitorById.id))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.id === updatedMember?.setVisitorById.id ? updatedMember : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, updatedMember }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.id !== updatedMember?.setVisitorById.id)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'INSTALLERS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setInstallersMemberAction(data.setVisitorById, [installersGroupExternalRef!]);
    }
    return data;
  };

  const [unsetInstallerMutation, { error: unsetInstallerMutationError, loading: unsetInstallerMutationLoading }] =
    useMutation<UnsetInstaller, UnsetInstallerVariables>(UNSET_INSTALLER, {
      onCompleted: (data) => {
        if (data.unsetInstaller.email) unsetInstallerAction(data.unsetInstaller.email);
      },
      onError: () => {
        handleFetchError('Error while deleting installer');
      }
    });

  const unsetInstaller = async (email: string) => {
    const { data } = await unsetInstallerMutation({
      variables: { email },
      update(cache, { data: deletedMemberData }) {
        const groupWithDeletedMember = installersGroups.filter((group) =>
          group.members.some((member) => member.email === email)
        )[0];

        const updatedGroups = installersGroups.map((group) => {
          if (group.id === groupWithDeletedMember.id)
            return {
              ...group,
              members: group.members.filter((member) => member.email !== deletedMemberData?.unsetInstaller.email)
            };
          return group;
        });

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'INSTALLERS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const [
    unsetInstallerByIdMutation,
    { error: unsetInstallerByIdMutationError, loading: unsetInstallerByIdMutationLoading }
  ] = useMutation<UnsetInstallerById, UnsetInstallerByIdVariables>(UNSET_INSTALLER_BY_ID, {
    onCompleted: (data) => {
      unsetInstallerByIdAction(data.unsetInstallerById.id);
    },
    onError: () => {
      handleFetchError('Error while deleting installer');
    }
  });

  const unsetInstallerById = async (visitorId: string) => {
    const { data } = await unsetInstallerByIdMutation({
      variables: { visitorId },
      update(cache, { data: deletedMemberData }) {
        const groupWithDeletedMember = installersGroups.filter((group) =>
          group.members.some((member) => member.id === visitorId)
        )[0];
        const updatedGroups = installersGroups.map((group) => {
          if (group.id === groupWithDeletedMember.id)
            return {
              ...group,
              members: group.members.filter((member) => member.id !== deletedMemberData?.unsetInstallerById.id)
            };
          return group;
        });

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'INSTALLERS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  return {
    installersGroups,
    setInstallerMember,
    setInstallerMemberLoading,
    unsetInstaller,
    unsetInstallerMutationLoading,
    unsetInstallerMutationError,
    unsetInstallerById,
    unsetInstallerByIdMutationLoading,
    unsetInstallerByIdMutationError,
    loading,
    updateInstallerMember,
    updateInstallerMemberLoading,
    updateInstallerByIdMember,
    updateInstallerByIdMemberLoading
  };
};

export default useInstallers;
