/* eslint-disable import/prefer-default-export */
import {
  GetCurrentHost_getCurrentHost,
  GetCurrentHost_getCurrentHost_mobileDevices
} from 'graphql/generated/GetCurrentHost';
import { createMockedMobileDevice } from './mobileDevice';

interface Props {
  id: string;
  email?: GraphQL_AWSEmail;
  isAdmin?: boolean | null;
  isAccessByScheduleEnabled?: boolean;
  mobileDevices?: GetCurrentHost_getCurrentHost_mobileDevices[];
}

export function createMockedHost({
  email = 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com',
  isAdmin = true,
  id = 'mockedUserAzureId',
  isAccessByScheduleEnabled = false,
  mobileDevices = [createMockedMobileDevice({ id: 'mockedMobileDevice' })]
}: Props): GetCurrentHost_getCurrentHost {
  return {
    __typename: 'Host',
    email,
    id,
    isAdmin,
    isAccessByScheduleEnabled,
    mobileDevices
  };
}
