import React from 'react';
import { Colors } from 'theme/theme';
import styled from 'styled-components';
import { WidthOrHeight } from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Typography from '../Typography/Typography';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';

export interface CssProps {
  firstLineOpacity?: string;
  firstLineColor?: keyof Colors;
  secondLineColor?: keyof Colors;
  flex?: string;
  isLoading?: boolean;
  width?: WidthOrHeight;
}

export type Props = CssProps & {
  firstLineText: string;
  secondLineText?: string;
  id?: string;
  firstLineId?: string;
  secondLineId?: string;
};

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ width }) => width && `width:${width};`};
`;

const TableCell: React.FC<Props> = ({
  firstLineText,
  secondLineText,
  firstLineOpacity,
  firstLineColor = 'lTextHigh',
  secondLineColor = 'lTextMedium',
  flex = 'auto',
  width,
  id,
  firstLineId,
  secondLineId,
  isLoading = false
}) => (
  <TableCellWrapper flex={flex} width={width} id={id}>
    {isLoading ? (
      <TypographySkeleton variant="body3">{firstLineText}</TypographySkeleton>
    ) : (
      <>
        <Typography
          whiteSpace="nowrap"
          variant="body3"
          opacity={firstLineOpacity}
          color={firstLineColor}
          id={firstLineId}
        >
          {firstLineText}
        </Typography>
        {secondLineText && (
          <Typography whiteSpace="nowrap" variant="body4" color={secondLineColor} id={secondLineId}>
            {secondLineText}
          </Typography>
        )}
      </>
    )}
  </TableCellWrapper>
);

export default TableCell;
