/* eslint-disable import/prefer-default-export */
import {
  GetLocationsWithDoorsForTenant,
  GetLocationsWithDoorsForTenant_getLocationsForTenant
} from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { LocationType } from 'graphql/generated/globalTypes';
import { createLocationWithDoors } from 'mocks/objectsBaseOnSchemaType/locationWithDoors';
import { mockedGetDoorsForTenantDataForQuery } from './getDoorsForTenant';

export function mockedGetLocationWithDoorsForTenantData(
  getLocationsForTenant: Array<GetLocationsWithDoorsForTenant_getLocationsForTenant>
): GetLocationsWithDoorsForTenant {
  return {
    getLocationsForTenant
  };
}

export const mockedGetLocationWithDoorsForTenantDataDataForQuery = mockedGetLocationWithDoorsForTenantData([
  createLocationWithDoors({
    id: 'mockedLocationId0',
    name: 'lock group 0 (without doors)',
    externalRef: 'mockedExternalLocationRef0',
    type: LocationType.MANUAL
  }),
  createLocationWithDoors({
    id: 'mockedLocationId1',
    name: 'lock group 1',
    externalRef: 'mockedExternalLocationRef1',
    type: LocationType.MANUAL,
    doors: [mockedGetDoorsForTenantDataForQuery.getDoorsForTenant[0]]
  }),
  createLocationWithDoors({
    id: 'mockedLocationId2',
    name: 'lock group 2(all doors assigned)',
    externalRef: 'mockedExternalLocationRef2',
    type: LocationType.MANUAL,
    doors: [...mockedGetDoorsForTenantDataForQuery.getDoorsForTenant]
  })
]);
