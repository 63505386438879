import { IS_MOBILE_WIDTH } from 'hooks/useMobileDetector';
import {
  AccessEventFiltersState,
  AuthState,
  CoContractorsGroupsState,
  DoorsState,
  DoorsWithLocationsStateComplete,
  LocationsState,
  LocationsWithDoorsState,
  OfficeAvailableDaysState,
  OfficeDaysState,
  PermissionsState,
  RequestState,
  SchedulesState,
  UiState,
  VisitorGroupsWithIsOfficeModeEnabledState,
  VisitorsGroupsMembersState,
  VisitorsGroupsState,
  VisitorsGroupsWithAccessGrantsState
} from './types';

export const doorsInitialState: DoorsState = [];
export const doorsWithLocationsInitialState: DoorsWithLocationsStateComplete = [];

export const authInitialState: AuthState = {
  loading: true,
  user: null
};

export const locationsInitialState: LocationsState = [];
export const locationsWithDoorsInitialState: LocationsWithDoorsState = [];

// @ts-ignore
export const uiInitialState: UiState = {
  drawerState: null,
  isMobile: window.innerWidth < IS_MOBILE_WIDTH,
  isMobileLandscape: window.innerWidth > window.innerHeight
};

export const visitorsGroupsInitialState: VisitorsGroupsState = [];
export const installersGroupsInitialState: VisitorsGroupsMembersState = [];
export const visitorsGroupsMembersInitialState: VisitorsGroupsMembersState = [];
export const visitorsGroupsWithAccessGrantsState: VisitorsGroupsWithAccessGrantsState = [];
export const coContractorsGroupsInitialState: CoContractorsGroupsState = [];
export const schedulesInitialState: SchedulesState = [];

export const requestStateInitialState: RequestState = {
  inProgress: false
};

export const accessEventFiltersState: AccessEventFiltersState = { filters: {}, appliedFilters: {} };

export const officeDaysInitialState: OfficeDaysState = [];
export const officeAvailableDaysInitialState: OfficeAvailableDaysState = [];

export const permissionsInitialState: PermissionsState = [];

export const visitorGroupsWithIsOfficeModeEnabledInitialState: VisitorGroupsWithIsOfficeModeEnabledState = [];
