import React from 'react';
import styled from 'styled-components';
import Button, { Props as ButtonProps } from '../Button/Button';

export interface CssProps {
  flex?: string;
}

export type Props = CssProps & {
  buttonProps?: Pick<ButtonProps, 'children' | 'textColor' | 'backgroundColor' | 'disabled' | 'onClick'>;
  onClick?: () => void;
  id?: string;
};

const TableHeaderWrapper = styled.div<Partial<Props>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ onClick }) => onClick && `cursor: pointer`};
`;

const TableHeaderButton: React.FC<Props> = ({ buttonProps, flex, onClick, id }) => (
  <TableHeaderWrapper flex={flex} id={id} onClick={onClick}>
    <Button {...buttonProps} />
  </TableHeaderWrapper>
);

export default TableHeaderButton;
