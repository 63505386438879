import { AddExternalUserForm } from 'components/Drawer/types';
import useCoContractors from 'hooks/useCoContractors/useCoContractors';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddExternalUserDrawer } from 'state/types';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AddExternalUser from 'ui/organisms/Drawer/variants/AddExternalUser';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useModal from 'hooks/useModal/useModal';
import dayjs from 'dayjs';
import { OptionProps } from 'ui/molecules/Select/Select';
import { Role } from 'graphql/generated/globalTypes';
import { EmailInputValidation, ExpirationDateInputValidation, NameInputValidation } from '../validationTexts';
import {
  handleExpirationDateInputValidation,
  handleExpirationDateInputValue,
  handleNameInputValidation,
  handleSwitchIsExpirationDateOnChange
} from '../coContractorGroup/shared';
import { emailRegex, roleSelectOptions } from './shared';

const AddExternalUserVariant: React.FC = () => {
  const { register, handleSubmit, setValue, control } = useForm<AddExternalUserForm>();
  const { drawerState } = useDrawer<AddExternalUserDrawer>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { coContractorsGroups, addVisitorMemberWithRole, addVisitorMemberMutationWithRoleLoading } = useCoContractors({
    handleFetchError
  });
  const { hideDrawer } = useDrawer();
  const { showModal } = useModal();
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameInputValidation, setNameInputValidation] = useState<NameInputValidation>(undefined);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emailInputValidation, setEmailInputValidation] = useState<EmailInputValidation>(undefined);
  const [isExpirationDateEnabled, setIsExpirationDateEnabled] = useState<boolean>();
  const [expirationDateInputValue, setExpirationDateInputValue] = useState<string>();
  const [expirationDateTo, setExpirationDateTo] = useState<string>();
  const [expirationDateInputValidation, setExpirationDateInputValidation] =
    useState<ExpirationDateInputValidation>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const [selectInputValue, setSelectInputValue] = useState<string>('User');
  const [selectValue, setSelectValue] = useState<OptionProps<Role | null>>({
    label: 'User',
    value: Role.EXTERNAL_GROUP_USER
  });

  const onSubmit = async () => {
    setShowValidation(true);
    if (
      nameInputValidation === undefined &&
      emailInputValidation === undefined &&
      expirationDateInputValidation === undefined &&
      !addVisitorMemberMutationWithRoleLoading &&
      selectValue &&
      selectValue.value
    ) {
      addVisitorMemberWithRole(
        nameInputValue,
        emailInputValue,
        [drawerState.contentValue.visitorGroupExternalRef],
        [{ visitorGroupExternalRef: drawerState.contentValue.visitorGroupExternalRef, role: selectValue.value }],
        isExpirationDateEnabled ? expirationDateTo : undefined
      );

      hideDrawer();
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
    setValue('email', emailInputValue);
  }, [emailInputValue, nameInputValue, setValue]);

  useEffect(() => {
    if (emailInputValue === '') setEmailInputValidation('Please enter an email address');
    else if (!emailInputValue.toLowerCase().match(emailRegex))
      setEmailInputValidation('This is not a correct email address');
    else if (coContractorsGroups.some(({ members }) => members.some((member) => member.email === emailInputValue)))
      setEmailInputValidation('Email has already been added');
    else setEmailInputValidation(undefined);
  }, [coContractorsGroups, emailInputValue]);

  const handleExpirationDateInputOnClick = () => {
    if (isExpirationDateEnabled)
      showModal({
        type: 'expirationDate',
        contentValue: {
          expirationDateTo: expirationDateTo ? dayjs(expirationDateTo) : null,
          setExpirationDateTo
        }
      });
  };

  const handleSelectChange = (value: OptionProps<Role> | null) => {
    if (value) setSelectValue(value);
    else setSelectValue({ label: '', value: null });
  };

  useEffect(() => {
    handleNameInputValidation(setNameInputValidation, nameInputValue);
  }, [nameInputValue]);

  useEffect(() => {
    handleExpirationDateInputValidation(setExpirationDateInputValidation, isExpirationDateEnabled, expirationDateTo);
  }, [expirationDateTo, isExpirationDateEnabled]);

  useEffect(() => {
    handleExpirationDateInputValue(setExpirationDateInputValue, expirationDateTo);
  }, [expirationDateTo]);

  useEffect(() => {
    if (isExpirationDateEnabled === undefined) setIsExpirationDateEnabled(false);
  }, [isExpirationDateEnabled]);

  return (
    <DrawerTemplate
      title="Add external user"
      confirmButtonText="Add"
      id="drawer-add-external-group-member"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={showValidation && (nameInputValidation !== undefined || emailInputValidation !== undefined)}
    >
      <AddExternalUser
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={setNameInputValue}
        nameInputValidation={nameInputValidation}
        emailInputValue={emailInputValue}
        handleEmailInputValueChange={setEmailInputValue}
        emailInputValidation={emailInputValidation}
        showValidation={showValidation}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
        inputExpirationDateToValue={expirationDateInputValue}
        handleExpirationDateInputOnClick={handleExpirationDateInputOnClick}
        handleSwitchIsExpirationDateOnChange={() =>
          handleSwitchIsExpirationDateOnChange(
            setIsExpirationDateEnabled,
            setExpirationDateTo,
            setExpirationDateInputValue,
            isExpirationDateEnabled,
            expirationDateTo
          )
        }
        switchIsExpirationDateEnabled={isExpirationDateEnabled}
        inputExpirationDateToValueValidation={expirationDateInputValidation}
        control={control}
        selectValue={selectValue}
        inputValue={selectInputValue}
        selectOption={roleSelectOptions}
        handleSelectChange={handleSelectChange}
        handleSelectInputChange={setSelectInputValue}
      />
    </DrawerTemplate>
  );
};
export default AddExternalUserVariant;
