import { EditCoContractorForm } from 'components/Drawer/types';
import { ExpirationDateInputValidation, NameInputValidation } from 'components/Drawer/variants/validationTexts';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const StyledIconBackground = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: ${(props) => props.theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;
export interface Props {
  register: UseFormRegister<EditCoContractorForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string;
  group: string;
  status: boolean;
  handleOpenChangeGroupCoContractorModal: () => void;
  showValidation: boolean;
  isResendInvitationButtonVisible: boolean;
  handleOnResendInvitationClick: () => void;
  switchIsExpirationDateEnabled: boolean;
  handleSwitchIsExpirationDateOnChange: () => void;
  inputExpirationDateToValue: string | undefined;
  handleExpirationDateInputOnClick: () => void;
  inputExpirationDateToValueValidation: ExpirationDateInputValidation;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EditCoContractor: React.FC<Props> = ({
  register,
  nameInputValue,
  emailInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  group,
  status,
  handleOpenChangeGroupCoContractorModal,
  showValidation,
  isResendInvitationButtonVisible,
  handleOnResendInvitationClick,
  switchIsExpirationDateEnabled,
  handleSwitchIsExpirationDateOnChange,
  inputExpirationDateToValue,
  handleExpirationDateInputOnClick,
  inputExpirationDateToValueValidation,
  inputOnKeyDown
}) => (
  <>
    <ComponentWrapper flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
      <Typography color="dTextHigh" variant="label">
        ATLAS Access App
      </Typography>
      <Icon
        name={status ? 'StatusConnected' : 'StatusDisconnected'}
        color={status ? 'success' : 'error'}
        // eslint-disable-next-line no-magic-numbers
        width={status ? 75 : 92}
        height={12}
      />
      <Typography color="dTextHigh" variant="label">
        Group
      </Typography>
      <ComponentWrapper alignItems="center" justifyContent="space-between" width="100%">
        <Typography color="dTextHigh" variant="body1">
          {group}
        </Typography>
        <StyledIconBackground>
          <Icon
            name="OpenModal"
            color="lTextHigh"
            onClick={handleOpenChangeGroupCoContractorModal}
            id="drawer-group-button"
          />
        </StyledIconBackground>
      </ComponentWrapper>
      <Input
        {...register('name')}
        onChange={(event) => handleNameInputOnChange(event.target.value)}
        customRequired
        label="Name*"
        value={nameInputValue}
        id="drawer-input-name"
        failed={showValidation && nameInputValidation !== undefined}
        failedText={nameInputValidation}
        failedId="drawer-input-name-validation"
        onKeyDown={inputOnKeyDown}
      />
      <Input
        {...register('email')}
        required
        label="E-mail*"
        value={emailInputValue}
        id="drawer-input-email"
        disabled
        onKeyDown={inputOnKeyDown}
      />
      {isResendInvitationButtonVisible && (
        <Button type="button" onClick={handleOnResendInvitationClick} id="resend-access-invitation-button">
          Resend invitation
        </Button>
      )}

      <Input
        {...register('expirationDate')}
        customRequired
        label="Expiration date"
        failed={showValidation && inputExpirationDateToValueValidation !== undefined}
        value={inputExpirationDateToValue}
        failedText={inputExpirationDateToValueValidation}
        disabled={!switchIsExpirationDateEnabled}
        failedId="drawer-input-expiration-date-validation"
        id="drawer-input-expiration-date"
        onKeyDown={inputOnKeyDown}
        onClick={switchIsExpirationDateEnabled ? handleExpirationDateInputOnClick : undefined}
      />
      <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body1" color="dTextHigh">
          Never expire
        </Typography>
        <Switch
          variant="dark"
          {...register('isOfficeModeEnabled')}
          id="drawer-enable-expiration-date-span"
          inputId="drawer-enable-expiration-date-input"
          checked={!switchIsExpirationDateEnabled}
          onClick={handleSwitchIsExpirationDateOnChange}
          onKeyDown={inputOnKeyDown}
        />
      </ComponentWrapper>
    </ComponentWrapper>
  </>
);

export default EditCoContractor;
