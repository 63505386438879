/* eslint-disable import/prefer-default-export */

import { onRegistrationTokenSaved_onRegistrationTokenSaved } from 'graphql/generated/onRegistrationTokenSaved';

interface Props {
  email?: GraphQL_AWSEmail;
  registrationToken?: string;
}

export function createMockedSubscriptionRegistrationTokenSaved({
  email = 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com',
  registrationToken = ''
}: Props): onRegistrationTokenSaved_onRegistrationTokenSaved {
  return {
    __typename: 'SubscriptionRegistrationTokenSaved',
    email,
    registrationToken
  };
}
