/* eslint-disable import/prefer-default-export */

import { SetVisitorWithRole, SetVisitorWithRole_setVisitor } from 'graphql/generated/SetVisitorWithRole';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function mockedSetVisitorWithRoleData(setVisitor: SetVisitorWithRole_setVisitor): SetVisitorWithRole {
  return {
    setVisitor
  };
}

export const mockedSetVisitorWithRoleDataForMutation = mockedSetVisitorWithRoleData(
  createMockedVisitorWithVisitorGroupMembership({
    id: 'mockedVisitorIdCoContractor6',
    name: 'mockedVisitorEmailCoContractor6Edited',
    email: 'mockedVisitorEmailCoContractor6'
  })
);
