/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import { GetCurrentHost, GetCurrentHost_getCurrentHost } from 'graphql/generated/GetCurrentHost';
import { createMockedHost } from 'mocks/objectsBaseOnSchemaType/host';
import { createMockedMobileDevice } from 'mocks/objectsBaseOnSchemaType/mobileDevice';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function mockedGetCurrentHostData(getCurrentHost: GetCurrentHost_getCurrentHost): GetCurrentHost {
  return {
    getCurrentHost
  };
}

export const mockedGetCurrentHostDataForQuery = mockedGetCurrentHostData(
  createMockedHost({
    email: 'bob-atlas@tkhsecurityatlasdev.onmicrosoft.com',
    isAdmin: true,
    id: 'mockedUserId',
    isAccessByScheduleEnabled: false,
    mobileDevices: [
      createMockedMobileDevice({
        id: 'mockedMobileDevice',
        isRegistered: true,
        registrationDate: dayjs().utc().format()
      })
    ]
  })
);
