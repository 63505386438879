import { EditExternalUserForm } from 'components/Drawer/types';
import { ExpirationDateInputValidation, NameInputValidation } from 'components/Drawer/variants/validationTexts';
import { Role } from 'graphql/generated/globalTypes';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Switch from 'ui/atoms/Switch/Switch';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const StyledIconBackground = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: ${(props) => props.theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  align-items: center;
  margin-top: 1.5rem;
`;

export interface Props {
  register: UseFormRegister<EditExternalUserForm>;
  nameInputValue: string | undefined;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string | undefined;
  group: string;
  status: boolean | undefined;
  handleOpenChangeGroupCoContractorModal: () => void;
  showValidation: boolean;
  isResendInvitationButtonVisible: boolean | undefined;
  handleOnResendInvitationClick: () => void;
  switchIsExpirationDateEnabled: boolean | undefined;
  handleSwitchIsExpirationDateOnChange: () => void;
  inputExpirationDateToValue: string | undefined;
  handleExpirationDateInputOnClick: () => void;
  inputExpirationDateToValueValidation: ExpirationDateInputValidation;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  control: Control<EditExternalUserForm, object>;
  selectValue: null | OptionProps<Role | null>;
  inputValue: string;
  selectOption: OptionProps<Role | null>[];
  handleSelectInputChange: (value: string) => void;
  handleSelectChange: (value: OptionProps<Role> | null) => void;
}

const EditExternalUser: React.FC<Props> = ({
  register,
  nameInputValue,
  emailInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  group,
  status,
  handleOpenChangeGroupCoContractorModal,
  showValidation,
  isResendInvitationButtonVisible,
  handleOnResendInvitationClick,
  switchIsExpirationDateEnabled,
  handleSwitchIsExpirationDateOnChange,
  inputExpirationDateToValue,
  handleExpirationDateInputOnClick,
  inputExpirationDateToValueValidation,
  inputOnKeyDown,
  control,
  selectValue,
  inputValue,
  selectOption,
  handleSelectChange,
  handleSelectInputChange
}) => (
  <ComponentWrapper flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
    <Typography color="dTextHigh" variant="label">
      ATLAS Access App
    </Typography>
    <Icon
      name={status ? 'StatusConnected' : 'StatusDisconnected'}
      color={status ? 'success' : 'error'}
      // eslint-disable-next-line no-magic-numbers
      width={status ? 75 : 92}
      height={12}
    />
    <Typography color="dTextHigh" variant="label">
      Group
    </Typography>
    <ComponentWrapper alignItems="center" justifyContent="space-between" width="100%">
      <Typography color="dTextHigh" variant="body1">
        {group}
      </Typography>
      <StyledIconBackground>
        <Icon
          name="OpenModal"
          color="lTextHigh"
          onClick={handleOpenChangeGroupCoContractorModal}
          id="drawer-group-button"
        />
      </StyledIconBackground>
    </ComponentWrapper>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      customRequired
      label="Name*"
      value={nameInputValue}
      id="drawer-input-name"
      failed={showValidation && nameInputValidation !== undefined}
      failedText={nameInputValidation}
      failedId="drawer-input-name-validation"
      onKeyDown={inputOnKeyDown}
    />
    <Input
      {...register('email')}
      required
      label="E-mail*"
      value={emailInputValue}
      id="drawer-input-email"
      disabled
      onKeyDown={inputOnKeyDown}
    />
    <ComponentWrapper>
      <Controller
        control={control}
        name="role"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: 'Role'
            }}
            autocompleteProps={{
              options: selectOption,
              value: selectValue,
              inputValue,
              onInputChange: (_, value) => {
                if (value !== 'undefined') handleSelectInputChange(value);
              },
              onChange: (_, value) => {
                handleSelectChange(value as OptionProps<Role>);
              },
              // @ts-ignore
              getOptionLabel: (option) => option.label as string,
              id: 'drawer-select-role-id',
              onKeyDown: inputOnKeyDown,
              sx: { width: 343 },
              disableClearable: true
            }}
            textFieldProps={{ placeholder: 'No role selected' }}
            {...register('role')}
          />
        )}
      />
      <Tooltip
        elementOnHover={
          <ComponentWrapper flexDirection="column" width="20rem" padding="1rem">
            <Typography variant="body4" color="lTextHigh">
              A user with the “Owner” role has the ability
            </Typography>
            <Typography variant="body4" color="lTextHigh">
              to invite other users to this group up to
            </Typography>
            <Typography variant="body4" color="lTextHigh">
              maximum user count.
            </Typography>
          </ComponentWrapper>
        }
        id="role-tooltip"
      >
        <IconWrapper>
          <Icon name="Info" id="locks-i-icon-role" />
        </IconWrapper>
      </Tooltip>
    </ComponentWrapper>
    {isResendInvitationButtonVisible && (
      <Button type="button" onClick={handleOnResendInvitationClick} id="resend-access-invitation-button">
        Resend invitation
      </Button>
    )}

    <Input
      {...register('expirationDate')}
      customRequired
      label="Expiration date"
      failed={showValidation && inputExpirationDateToValueValidation !== undefined}
      value={inputExpirationDateToValue}
      failedText={inputExpirationDateToValueValidation}
      disabled={!switchIsExpirationDateEnabled}
      failedId="drawer-input-expiration-date-validation"
      id="drawer-input-expiration-date"
      onKeyDown={inputOnKeyDown}
      onClick={switchIsExpirationDateEnabled ? handleExpirationDateInputOnClick : undefined}
    />
    <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
      <Typography variant="body1" color="dTextHigh">
        Never expire
      </Typography>
      <Switch
        variant="dark"
        {...register('isOfficeModeEnabled')}
        id="drawer-enable-expiration-date-span"
        inputId="drawer-enable-expiration-date-input"
        checked={!switchIsExpirationDateEnabled}
        onClick={handleSwitchIsExpirationDateOnChange}
        onKeyDown={inputOnKeyDown}
      />
    </ComponentWrapper>
  </ComponentWrapper>
);

export default EditExternalUser;
