import { useReactiveVar } from '@apollo/client';
import useBatteryStatusUI from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import { orderBy } from 'lodash';
import { headers } from 'pages/Hardware/Locks/Locks';
import { useCallback, useState } from 'react';
import { GetDoorsForTenantWithLocations_getDoorsForTenant_complete } from 'state/types';
import { doorsWithLocationsVar } from 'state/vars';

interface Sorting {
  isAscending?: boolean;
  columnName?: typeof headers[number];
}

const useSortingLocks = () => {
  const doorsWithLocations = useReactiveVar(doorsWithLocationsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: 'Name' });
  const { getBatteryStatus, getBatteryData } = useBatteryStatusUI();

  const handleSorting = useCallback(
    (item: GetDoorsForTenantWithLocations_getDoorsForTenant_complete, columnName?: typeof headers[number]) => {
      switch (columnName) {
        case 'Serial number':
          return item.domSerialNumber;
        case 'Room':
          return item.externalLocation?.name;
        case 'Battery status':
          return getBatteryData(getBatteryStatus(item.batteryWarningLevel)).sortNumber;
        case 'Office mode':
          return item.officeMode.weekDays.some((day) => day !== null)
            ? // eslint-disable-next-line no-magic-numbers
              item.officeMode.weekDays[0].from.slice(0, -3)
            : '';
        case 'Name':
        default:
          return item.name;
      }
    },
    [getBatteryData, getBatteryStatus]
  );

  const handleSortingOnClick = useCallback(
    (columnName: typeof headers[number]) => {
      if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
        return setSorting({ isAscending: !sorting.isAscending, columnName });
      }
      return setSorting({ isAscending: true, columnName });
    },
    [sorting.columnName, sorting.isAscending]
  );

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  const getSortedArray = useCallback(
    () =>
      orderBy(doorsWithLocations, (item) => handleSorting(item, sorting.columnName), [
        sorting.isAscending ? 'asc' : 'desc'
      ]),
    [doorsWithLocations, handleSorting, sorting.columnName, sorting.isAscending]
  );

  return { handleSortingOnClick, handleSortingIcon, getSortedArray };
};

export default useSortingLocks;
