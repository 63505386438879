/* eslint-disable import/prefer-default-export */

import {
  SetAccessGrantsForGroups,
  SetAccessGrantsForGroups_setAccessGrantsForGroups
} from 'graphql/generated/SetAccessGrantsForGroups';
import { createMockedAccessGrant } from 'mocks/objectsBaseOnSchemaType/accessGrant';
import { createMockedDoor } from 'mocks/objectsBaseOnSchemaType/door';
import { createMockedVisitorGroupWithType } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithType';

export function mockedSetAccessGrantsForGroupsData(
  setAccessGrantsForGroups: SetAccessGrantsForGroups_setAccessGrantsForGroups
): SetAccessGrantsForGroups {
  return {
    setAccessGrantsForGroups
  };
}

export const mockedSetAccessGrantsForGroupsDataForMutation0 = mockedSetAccessGrantsForGroupsData(
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId0',
    defaultAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId0', doors: [createMockedDoor({ id: 'mockedDoorId0' })] })
    ]
  })
);

export const mockedSetAccessGrantsForGroupsDataForMutation1 = mockedSetAccessGrantsForGroupsData(
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId1',
    scheduledAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId0', doors: [createMockedDoor({ id: 'mockedDoorId0' })] })
    ]
  })
);
