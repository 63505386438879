import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES } from 'graphql/queries';
import { useEffect } from 'react';
import { GetVisitorGroupsForTenantWithSchedules } from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { schedulesVar } from 'state/vars';
import { setSchedulesAction, updateAccessGrantAction } from 'state/actions/schedules';
import { UPDATE_ACCESS_GRANT } from 'graphql/mutations';
import { UpdateAccessGrant, UpdateAccessGrantVariables } from 'graphql/generated/UpdateAccessGrant';
import { UpdateAccessGrantInput } from 'graphql/generated/globalTypes';
import { BaseHookProps } from '../shared/types';

export type DayName = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const useSchedules = ({ handleFetchError }: BaseHookProps) => {
  const schedules = useReactiveVar(schedulesVar);

  const [getVisitorGroupsWithSchedules, { loading }] = useLazyQuery<GetVisitorGroupsForTenantWithSchedules>(
    GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES,
    {
      onCompleted: (data) => {
        setSchedulesAction(data.getVisitorGroupsForTenant.filter((group) => group.type !== 'ATLAS_INTERNAL'));
      },
      onError: () => {
        handleFetchError('Error while fetching schedules');
      }
    }
  );

  const [updateAccessGrantMutation, { loading: updateAccessGrantMutationLoading }] = useMutation<
    UpdateAccessGrant,
    UpdateAccessGrantVariables
  >(UPDATE_ACCESS_GRANT, {
    onCompleted: (data) => {
      updateAccessGrantAction(data.updateAccessGrant);
    },
    onError: () => {
      handleFetchError('Error while updating schedule');
    }
  });

  useEffect(() => {
    getVisitorGroupsWithSchedules();
  }, [getVisitorGroupsWithSchedules]);

  const updateAccessGrant = async (accessGrantId: string, input: UpdateAccessGrantInput) => {
    const { data } = await updateAccessGrantMutation({
      variables: {
        accessGrantId,
        input
      },
      // Here Apollo doesn't update cache automatically, we have to do it manually
      update(cache, { data: updatedSchedule }) {
        const visitorGroupWithUpdatedScheduleIndex = schedules.findIndex((group) =>
          group[
            group.defaultAccessGrants.length > group.scheduledAccessGrants.length
              ? 'defaultAccessGrants'
              : 'scheduledAccessGrants'
          ].some((grant) => grant.id === updatedSchedule?.updateAccessGrant.id)
        );
        const visitorGroupWithUpdatedScheduleAccessMode =
          schedules[visitorGroupWithUpdatedScheduleIndex].defaultAccessGrants.length >
          schedules[visitorGroupWithUpdatedScheduleIndex].scheduledAccessGrants.length;
        const updatedVisitorGroups = schedules.map((group, index) =>
          index === visitorGroupWithUpdatedScheduleIndex
            ? {
                ...group,
                defaultAccessGrants: visitorGroupWithUpdatedScheduleAccessMode ? [updatedSchedule] : [],
                scheduledAccessGrants: visitorGroupWithUpdatedScheduleAccessMode ? [] : [updatedSchedule]
              }
            : group
        );

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES,
          data: {
            getVisitorGroupsForTenant: updatedVisitorGroups
          }
        });
      }
    });
    return data;
  };

  return {
    schedules,
    updateAccessGrant,
    updateAccessGrantMutationLoading,
    loading
  };
};

export default useSchedules;
