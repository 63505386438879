/* eslint-disable import/prefer-default-export */

import {
  UnassignAccessGrantsFromDoors,
  UnassignAccessGrantsFromDoors_unassignAccessGrantsFromDoors
} from 'graphql/generated/UnassignAccessGrantsFromDoors';
import { createMockedAccessGrant } from 'mocks/objectsBaseOnSchemaType/accessGrant';
import { createMockedDoor } from 'mocks/objectsBaseOnSchemaType/door';

export function mockedUnassignAccessGrantsToDoors(
  unassignAccessGrantsFromDoors: UnassignAccessGrantsFromDoors_unassignAccessGrantsFromDoors[]
): UnassignAccessGrantsFromDoors {
  return {
    unassignAccessGrantsFromDoors
  };
}

export const mockedUnassignAccessGrantsToDoorsDataForMutation = mockedUnassignAccessGrantsToDoors([
  createMockedAccessGrant({ id: 'mockedAccessGrantId', doors: [createMockedDoor({ id: 'mockedDoorId' })] })
]);
