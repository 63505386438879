import { AddCoContractorForm } from 'components/Drawer/types';
import {
  EmailInputValidation,
  ExpirationDateInputValidation,
  NameInputValidation
} from 'components/Drawer/variants/validationTexts';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<AddCoContractorForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string;
  handleEmailInputValueChange: (value: string) => void;
  emailInputValidation: EmailInputValidation;
  switchIsExpirationDateEnabled: boolean;
  handleSwitchIsExpirationDateOnChange: () => void;
  inputExpirationDateToValue: string | undefined;
  handleExpirationDateInputOnClick: () => void;
  inputExpirationDateToValueValidation: ExpirationDateInputValidation;
  showValidation: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const AddCoContractor: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  emailInputValue,
  handleEmailInputValueChange,
  emailInputValidation,
  switchIsExpirationDateEnabled,
  handleSwitchIsExpirationDateOnChange,
  inputExpirationDateToValue,
  handleExpirationDateInputOnClick,
  inputExpirationDateToValueValidation,
  showValidation,
  inputOnKeyDown
}) => (
  <>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      customRequired
      label="Name*"
      failed={showValidation && nameInputValidation !== undefined}
      value={nameInputValue}
      failedText={nameInputValidation}
      failedId="drawer-input-name-validation"
      id="drawer-input-name"
      onKeyDown={inputOnKeyDown}
    />
    <Input
      {...register('email')}
      customRequired
      onChange={(event) => handleEmailInputValueChange(event.target.value)}
      label="E-Mail*"
      failed={showValidation && emailInputValidation !== undefined}
      value={emailInputValue}
      failedText={emailInputValidation}
      failedId="drawer-input-email-validation"
      id="drawer-input-email"
      onKeyDown={inputOnKeyDown}
    />
    <Input
      {...register('expirationDate')}
      customRequired
      label="Expiration date"
      failed={showValidation && inputExpirationDateToValueValidation !== undefined}
      value={inputExpirationDateToValue}
      failedText={inputExpirationDateToValueValidation}
      disabled={!switchIsExpirationDateEnabled}
      failedId="drawer-input-expiration-date-validation"
      id="drawer-input-expiration-date"
      onKeyDown={inputOnKeyDown}
      onClick={switchIsExpirationDateEnabled ? handleExpirationDateInputOnClick : undefined}
    />
    <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
      <Typography variant="body1" color="dTextHigh">
        Never expire
      </Typography>
      <Switch
        variant="dark"
        {...register('isOfficeModeEnabled')}
        id="drawer-enable-expiration-date-span"
        inputId="drawer-enable-expiration-date-input"
        checked={!switchIsExpirationDateEnabled}
        onClick={handleSwitchIsExpirationDateOnChange}
        onKeyDown={inputOnKeyDown}
      />
    </ComponentWrapper>
  </>
);

export default AddCoContractor;
